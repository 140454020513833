/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { TextBox } from '@syncfusion/ej2-inputs';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import RegioniProvince1 from '../assets/RegioniProvince1.json';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class PaginaAgenzie extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettings = {pageSize : 10, pageCount : 10};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.filterSettings = { type: 'CheckBox'};
        this.dsCompagnia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00002')))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.descrizione} });
        this.dsDivisione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00003')))?.map(elm => { return { VALUE: elm.id_divisione.toString(), TEXT: elm.descrizione, ID_COMPAGNIA: elm.id_compagnia.toString() } });
        this.DdlObj = null;
        this.DdlObj2 = null;
        this.DdlObj3 = null;
        this.DdlObj4 = null;
        this.elemSelezArea = null;
        this.elemSelezProvincia = null;
        this.elemSelezCompagnia = null;
        this.elemSelezDivisione = null;
        this.txtObj1 = null;
        this.editTemplate = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.elemSelezArea = args.rowData.id_area; this.DdlObj = new DropDownList({fields:{text: 'descrizione', value: 'id_area'}, placeholder:'Area/Ispettorato', popupHeight:'200px', floatLabelType: 'Auto',
                                                dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00007')))?.map(elm => {return {id_area: elm.id_area.toString(), descrizione: elm.descrizione}}), value: args.rowData.id_area?.toString(),
                                                allowFiltering: true, filterType: 'Contains',
                                                 change: (args2) => {
                                                     args.rowData.id_area = parseInt(args2.value);
                                                     this.elemSelezArea = parseInt(args2.value);
                                                    } }); this.DdlObj.appendTo(args.element) }};
        this.editTemplate2 = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                               read: () => {return this.DdlObj2.text},
                               destroy: () => {this.DdlObj2.destroy()},
                               write: (args) => {this.elemSelezProvincia = args.rowData.provincia; this.DdlObj2 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Provincia', popupHeight:'200px', 
                                                 dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1'))), value: args.rowData.provincia, floatLabelType: 'Auto', 
                                                 allowFiltering: true, filterType: 'Contains', 
                                                 change: (args2) => {
                                                     args.rowData.provincia = args2.value;
                                                     this.elemSelezProvincia = args2.value;
                                                    } }); this.DdlObj2.appendTo(args.element)}};
        this.editTemplate3 = { create: () => { let elemTXT = document.createElement('input'); return elemTXT },
                                read: () => {return this.txtObj1.value},
                                destroy: () => {this.txtObj1.destroy()},
                                write: (args) => { this.txtObj1 = new TextBox({ value: args.rowData.note, floatLabelType: 'Auto', placeholder: 'Note', multiline: true,
                                                    htmlAttributes: {rows: 5} }); this.txtObj1.appendTo(args.element) } };  
        this.editTemplate4 = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                                read: () => {return this.DdlObj3.text},
                                destroy: () => {this.DdlObj3.destroy()},
                                write: (args) => {this.elemSelezCompagnia = args.rowData.id_compagnia; this.DdlObj3 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Compagnia', popupHeight:'200px', 
                                                    dataSource: this.dsCompagnia, value: args.rowData.provincia, floatLabelType: 'Auto',  
                                                    allowFiltering: true, filterType: 'Contains', 
                                                    change: (args2) => {
                                                        args.rowData.id_compagnia = args2.value;
                                                        this.elemSelezCompagnia = args2.value;
                                                        let ddlObj = document.getElementById('GridAgenziedescr_divisione').ej2_instances[0];
                                                        ddlObj.enabled = !CustomJS.isNullEmpty(args2.value);
                                                        let tempQuery = new Query().where('ID_COMPAGNIA', 'equal', args2.value);
                                                        ddlObj.query = tempQuery;
                                                        ddlObj.value = null;
                                                        ddlObj.text = null;
                                                        ddlObj.dataBind();
                                                    } }); this.DdlObj3.appendTo(args.element)}};     
        this.editTemplate5 = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                                read: () => {return this.DdlObj4.text},
                                destroy: () => {this.DdlObj4.destroy()},
                                write: (args) => {this.elemSelezDivisione = args.rowData.id_divisione; this.DdlObj4 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Divisione', popupHeight:'200px', 
                                                    dataSource:this.dsDivisione, value: args.rowData.id_divisione, floatLabelType: 'Auto', enabled: false,
                                                    allowFiltering: true, filterType: 'Contains', 
                                                    change: (args2) => {
                                                        args.rowData.id_divisione = args2.value;
                                                        this.elemSelezDivisione = args2.value;
                                                    } }); this.DdlObj4.appendTo(args.element)}};    
    }
    state = {
        blocking: false,
        dsAgenzie: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    actionBegin(args) {
        if (args.requestType === 'add') 
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'descr_compagnia' || elm.field === 'descr_divisione') 
                    elm.visible = true;
            }); 
        if (args.action === "edit" && args.requestType === "save") {
            args.data.id_area = this.elemSelezArea;
            args.data.provincia = this.elemSelezProvincia;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuova agenzia' : `Dettaglio di ${args.rowData.ragione_sociale}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '00004';
            const dataObj = {
                IdAgenzia: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_agenzia : args.data[0].id_agenzia,
                IdCompagnia: args.rowData?.id_compagnia,  //IN REALTA' QUESTA NON SERVE PERCHE' LA COMPAGNIA IN FASE DI INSERIMENTO DI UNA NUOVA AGENZIA SERVE SOLO PER POTER SELEZIONARE UNA DIVISIONE
                IdDivisione: args.rowData?.id_divisione,
                RagioneSociale: CustomJS.isNullEmpty(args.data[0]) ? args.data.ragione_sociale : args.data[0].ragione_sociale,
                Indirizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.indirizzo : args.data[0].indirizzo,
                Citta: CustomJS.isNullEmpty(args.data[0]) ? args.data.citta : args.data[0].citta,
                Provincia: args.rowData?.provincia,
                CAP: CustomJS.isNullEmpty(args.data[0]) ? args.data.cap : args.data[0].cap,
                Telefono1: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                Email1: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Note: CustomJS.isNullEmpty(args.data[0]) ? args.data.note : args.data[0].note,
                IdArea: args.rowData?.id_area,
                Fax1: CustomJS.isNullEmpty(args.data[0]) ? args.data.fax1 : args.data[0].fax1
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }); 
                        this.gridInstance.columns.forEach(elm => {
                            if (elm.field === 'descr_compagnia' || elm.field === 'descr_divisione') 
                                elm.visible = false;
                        });                
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
                setTimeout(() => {
                    this.caricaDati();
                }, 200); 
        }
        if (args.requestType === 'cancel')
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'descr_compagnia' || elm.field === 'descr_divisione') 
                    elm.visible = false;
            }); 
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaAgenzia' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridAgenzie" dataSource={this.state.dsAgenzie} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} actionBegin={this.actionBegin.bind(this)} 
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} pageSettings={this.pageSettings} filterSettings={this.filterSettings} 
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_agenzia' isPrimaryKey='true'  visible={false} width='25%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='ragione_sociale' headerText="Ragione sociale"  width='27%' validationRules={{ required: true }} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_compagnia' headerText='Compagnia' visible={false} validationRules={{ required: true }} editType='DropDownEdit' edit={this.editTemplate4}></ColumnDirective>
                                                        <ColumnDirective field='descr_divisione' headerText='Divisione' visible={false} validationRules={{ required: true }} editType='DropDownEdit' edit={this.editTemplate5}></ColumnDirective>
                                                        <ColumnDirective field='descr_area' headerText="Area/Ispettorato"  width='40%' dataSource={this.aree} editType='DropDownEdit' edit={this.editTemplate} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='indirizzo' headerText="Indirizzo"  width='18%'  headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='citta' headerText="Città"  width='15%'  headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_provincia' headerText="Provincia"  width='12%' editType='DropDownEdit' edit={this.editTemplate2} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cap' headerText="CAP"  width='10%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono1' headerText="Telefono1" width='17%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono2' headerText="Telefono2"  width='10%' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='fax1' headerText="Fax1"  width='15%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email1' headerText="Email1"  width='25%'  headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='id_area' headerText="ID Area"  width='25%' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='note' headerText="Note"  width='25%' headerTemplate={headerTemplate}  edit={this.editTemplate3}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='15%' commands={this.commands} textAlign='Right' headerTemplate={headerTemplate} allowFiltering={false}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '00004', retData => {
            retData.forEach(elm => {
                let indOK = 0;
                let objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia ? idx : 0;  return elm3 === elm.provincia; })) })
                elm.descr_provincia = objReg?.capoluoghi[indOK];
                elm.id_compagnia = 0;
                elm.descr_compagnia = '';
                elm.id_divisione = 0;
                elm.descr_divisione = '';
            });
            this.gridInstance.dataSource = retData;
            if (!CustomJS.isNullEmpty(this.props.DescrAgenzia))
                this.gridInstance.search(this.props.DescrAgenzia);            
            else
                this.gridInstance.search();
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
        });
    }
}