/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import $ from 'jquery';
import { random, isUndefined } from 'underscore';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import ImageDelete from '../assets/Delete.png';
import ImageOK from '../assets/GreenOK.png';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    const returnDiv = props.column.field === 'eliminato' && props[props.column.field] === true ? <div><img id='imgLogo' src={ImageDelete} width='32px' height='32px'></img></div> 
                                             : <div><img id='imgLogo' src={ImageOK} width='32px' height='32px'></img></div>;

    return (returnDiv);
}
let elemDdl = null, salvaChildGridId = "", aggiornaRevisione = false;
export default class PaginaPolizze extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.editSettings2 = { allowAdding: true, allowEditing: true, allowDeleting: false, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.commands2 = [{ type: "Upload",buttonOption: { iconCss: "fas fa-upload fa-10x", cssClass: "e-details" }}, 
                          { type: "Vedi Polizza",buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" }}];
        this.filterSettings = { type: 'CheckBox'};
        this.selectionSettings = { enableToggle: false };
        this.sortSettings = { columns: [{ field: 'nome', direction: 'Ascending' }, { field: 'modello', direction: 'Ascending' }, { field: 'edizione', direction: 'Ascending' }] };
        this.asyncSettings =  {saveUrl: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/NoUtente/NoInc/POLIZZE`,
                               removeUrl: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/NoUtente/NoInc/POLIZZE`}
        this.childGrid = {
            queryString: 'id_tipo_polizza',
            editSettings: this.editSettings2,
            allowPaging: false,
            allowSelection: true,
            enableHover: true,
            showDeleteConfirmDialog: true,
            allowTextWrap:true,
            allowSorting:false, 
            allowFiltering:false,
            enableAltRow:true, 
            enablePersistence:false,
            actionComplete: this.actionComplete2.bind(this),
            pageSettings: { pageSize: 15, pageCount: 15 },
            //selectionSettings: { enableToggle: false },
            toolbar: [ 'Add', 'Edit', 'Update', 'Cancel', 'Search'],
            columns: [
                { field: 'id_tipo_polizza', visible: false },
                { field: 'id_file', headerText: '', isPrimaryKey: true, allowEditing: false, width: '0.1%' },
                { field: 'id_file_padre', headerText: '', allowEditing: false, width: '0.1%' },
                { field: 'nome_file', headerText: 'Nome File', width: '30%', allowEditing: false, headerTemplate: headerTemplate, validationRules: {required: true}},
                { field: 'descrizione', headerText: 'Descrizione', width: '30%', allowEditing: true,  headerTemplate: headerTemplate, validationRules: {required: true}},
                { field: 'rev', headerText: 'Revisione', textAlign: 'Center', allowEditing: false,  headerTemplate: headerTemplate, type: 'number', format: 'N0' },
                { field: 'utente', headerText: 'Utente', textAlign: 'Center', headerTemplate: headerTemplate, allowEditing: false },           
                { field: 'eliminato', headerText: 'Valido/Eliminato', textAlign: 'Center', headerTemplate: headerTemplate, template: fieldTemplate, editType: 'booleanedit' },
                { field: 'Comandi', headerText:"", commands: this.commands2, textAlign:'Right', headerTemplate: headerTemplate },
            ], 
            actionBegin: this.actionBegin2,
            commandClick: this.commandClick.bind(this),
            rowDataBound: this.onRowDatabound2
        };
        this.DdlObj = null;
        this.elemSelezPolizza = null;
        this.txtObj1 = null;
        this.editTemplate = { create: () => { elemDdl = document.createElement('input'); return elemDdl},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.elemSelezPolizza = args.rowData.id_tipo_polizza_padre; this.DdlObj = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Polizza Riferimento', floatLabelType: 'Auto', popupHeight:'200px', 
                                                dataSource:JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPolizzaRifer'))).filter(elm => { return elm.id_tipo_polizza !== args.rowData.id_tipo_polizza}).map(elm => {return {VALUE: elm.id_tipo_polizza.toString(), TEXT: elm.descrizione}}), 
            									value: args.rowData.id_tipo_polizza_padre?.toString(), allowFiltering: true, filterType: 'Contains',
                                                change: (args2) => {
                                                    args.rowData.id_tipo_polizza_padre = parseInt(args2.value);
                                                    this.elemSelezPolizza = parseInt(args2.value);
                                                } }); this.DdlObj.appendTo(elemDdl) }};  
        this.editTemplate2 = { create: () => { let elemTXT = document.createElement('input'); return elemTXT },
                               read: () => {return this.txtObj1.value},
                               destroy: () => {this.txtObj1.destroy()},
                               write: (args) => { this.txtObj1 = new TextBox({ value: args.rowData.note, floatLabelType: 'Auto', placeholder: 'Note', multiline: true,
                                                    htmlAttributes: {rows: 5} }); this.txtObj1.appendTo(args.element) } };        
    }
    state = {
        blocking: false,
        showDialog1: false,
        showLightbox: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {       
    }
    onRowDatabound2(args) {   
        $($(args.row).find('button')[0]).hide();
    }
    actionBegin(args){
        if (args.action === "edit" && args.requestType === "save") {
            args.data.id_tipo_polizza_padre = this.elemSelezPolizza;
        }
    }
    actionBegin2(args) {     
        if (args.requestType === 'add') { 
            salvaChildGridId = this.element.id;
            args.data.id_tipo_polizza = this.parentDetails.parentRowData.id_tipo_polizza;
            args.data.rev = 0;
            args.data.id_tipo_file = 1;
            args.data.eliminato = false;
            args.data.utente = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].username.toUpperCase();
            args.data.nome_file = `${this.parentDetails.parentRowData.nome}_${CustomJS.isNullEmpty(this.parentDetails.parentRowData.modello) ? '' : this.parentDetails.parentRowData.modello}_${CustomJS.isNullEmpty(this.parentDetails.parentRowData.edizione) ? '' : this.parentDetails.parentRowData.edizione}`;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.height = '65%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuova Polizza' : `Dettaglio di ${args.rowData.nome}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {   
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '02001';
            const dataObj = {
                IdTipoPolizza: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_tipo_polizza : args.data[0].id_tipo_polizza,
                Nome: CustomJS.isNullEmpty(args.data[0]) ? args.data.nome : args.data[0].nome,
                Modello: CustomJS.isNullEmpty(args.data[0]) ? args.data.modello : args.data[0].modello,
                Edizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.edizione : args.data[0].edizione,
                Preesistenza: CustomJS.isNullEmpty(args.data[0]) ? args.data.preesistenza : args.data[0].preesistenza,
                Note: CustomJS.isNullEmpty(args.data[0]) ? args.data.note : args.data[0].note,
                IdTipoPolizzaPadre: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_tipo_polizza_padre : args.data[0].id_tipo_polizza_padre,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }); 
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);                      
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    actionComplete2(args) {        
        if (args.requestType === 'add') {
            $($(args.row).find('button')[0]).hide();
            $($(args.row).find('button')[1]).hide();
            aggiornaRevisione = false;
            document.getElementById('uploadPolizze').querySelector('button.e-css.e-btn').click(); 
            setTimeout(() => {
                document.getElementById(salvaChildGridId).ej2_instances[0].toolbarModule.enableItems([`${salvaChildGridId}_update`], false);
            }, 100);
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Edit' : 'AzioneNonPrevista';
            const tabella = '01005';
            const dataObj = {
                IdFile: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_file : args.data[0].id_file,
                IdFilePadre: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_file_padre : args.data[0].id_file_padre,
                Rev: CustomJS.isNullEmpty(args.data[0]) ? args.data.rev : args.data[0].rev,
                NomeFile: CustomJS.isNullEmpty(args.data[0]) ? args.data.nome_file : args.data[0].nome_file,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione,
                Utente: CustomJS.isNullEmpty(args.data[0]) ? args.data.utente : args.data[0].utente,
                Host: CustomJS.isNullEmpty(args.data[0]) ? args.data.host : args.data[0].host,
                IdTipoFile: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_tipo_file : args.data[0].id_tipo_file,
                IdIntabella: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_tipo_polizza : args.data[0].id_tipo_polizza,
                Eliminato: CustomJS.isNullEmpty(args.data[0]) ? args.data.eliminato : args.data[0].eliminato,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {    
                    if (retData.response === 'KO') {                       
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200); 
                    }                         
            }, error => {                
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    toolbarClick(args) {
        this.gridInstance.excelExportComplete = (args) => {
            this.toggleBlocking();
        };
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.toggleBlocking();
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    commandClick(args) {
        if (args.commandColumn?.buttonOption.iconCss.indexOf('fa-upload') > -1) {
            salvaChildGridId = args.target.id.substring(0, args.target.id.indexOf('Upload'));
            aggiornaRevisione = true;
            document.getElementById('uploadPolizze').querySelector('button.e-css.e-btn').click();  
            document.getElementById(`${salvaChildGridId}nome_file`).ej2_instances[0].value = `${document.getElementById(salvaChildGridId).ej2_instances[0].parentDetails.parentRowData.nome}_${CustomJS.isNullEmpty(document.getElementById(salvaChildGridId).ej2_instances[0].parentDetails.parentRowData.modello) ? '' : document.getElementById(salvaChildGridId).ej2_instances[0].parentDetails.parentRowData.modello}_${CustomJS.isNullEmpty(document.getElementById(salvaChildGridId).ej2_instances[0].parentDetails.parentRowData.edizione) ? '' : document.getElementById(salvaChildGridId).ej2_instances[0].parentDetails.parentRowData.edizione}`;
            document.getElementById(salvaChildGridId).ej2_instances[0].toolbarModule.enableItems([`${salvaChildGridId}_update`], false);
        }  
        if (args.commandColumn?.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            let filePath = `${CustomJS.CartellaProdTipiPolizzaACT}/${args.rowData.nome_file.replaceAll('/','\\')}`;
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            this.toggleBlocking();
            if (args.rowData.nome_file.substring(args.rowData.nome_file.lastIndexOf('.')+1).toUpperCase() === 'PDF') {
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaDocumentoAWSS3/${filePath}`, '', 'GET',
                        '', JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, retData => { 
                    if (retData.code === 'NoSuchKey') {
                        this.toggleBlocking();
                        this.dialogInstance2.header = 'Attenzione!!';
                        this.dialogInstance2.content = 'Documento non presente in archivio!!';
                        this.dialogInstance2.width = '20%';
                        this.dialogInstance2.height = '18%';
                        this.dialogInstance.minHeight = '18%';
                        this.dialogInstance2.close = (args) => {
                            document.getElementById(salvaChildGridId).ej2_instances[0].clearSelection();
                        };
                        this.dialogInstance2.buttons = [{ click: () => { this.dialogInstance2.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance2.show();                                        
                        return;
                    }
                    if (CustomJS.isNullEmpty(this.props.ArrivaDaDettSinistro)) {
                        this.dialogInstance.header = 'VISUALIZZAZIONE POLIZZA';    
                        this.dialogInstance.width = '65%';
                        this.dialogInstance.height = '90%';
                        this.dialogInstance.minHeight = '90%';
                        this.dialogInstance.close = (args) => {
                            document.getElementById(salvaChildGridId).ej2_instances[0].clearSelection();
                        };
                        this.dialogInstance.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent');
                            const root = createRoot(container); 
                            root.unmount();
                        }       
                        this.dialogInstance.open = (args2) => {
                            const container = document.getElementById('defaultDialogContent');
                            const root = createRoot(container);                         
                            root.render(<CallbackWrapper callback={() => {
                                this.PDFViewerInstance.serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                                this.PDFViewerInstance.ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                                //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                                setTimeout(() => {
                                    this.PDFViewerInstance.load(`data:application/pdf;base64,${retData.base64Doc}`, null);
                                    this.toggleBlocking();
                                }, 250);
                            }}>{this.PDFViewerRender(args.rowData.nome_file)}</CallbackWrapper>);                     
                        };
                        this.dialogInstance.show();    
                    }
                    else {                        
                        this.dialogInstance10.header = 'VISUALIZZAZIONE POLIZZA';    
                        this.dialogInstance10.width = '65%';
                        this.dialogInstance10.height = '90%';
                        this.dialogInstance10.minHeight = '90%';
                        this.dialogInstance10.close = (args) => {
                            document.getElementById(salvaChildGridId).ej2_instances[0].clearSelection();
                        }
                        this.dialogInstance10.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent10');
                            const root = createRoot(container); 
                            root.unmount();
                        }       
                        this.dialogInstance10.open = (args2) => {
                            const container = document.getElementById('defaultDialogContent10');
                            const root = createRoot(container);    
                            root.render(<CallbackWrapper callback={() => {
                                this.PDFViewerInstance.serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                                this.PDFViewerInstance.ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                                //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                                setTimeout(() => {
                                    this.PDFViewerInstance.load(`data:application/pdf;base64,${retData.base64Doc}`, null);
                                    this.toggleBlocking();
                                }, 250);
                            }}>{this.PDFViewerRender(args.rowData.nome_file)}</CallbackWrapper>);
                        }
                        this.dialogInstance10.show(); 
                    }  
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true});
                    this.toggleBlocking();
                }, true, false);
            }
            if (args.rowData.nome_file.substring(args.rowData.nome_file.lastIndexOf('.')+1).toUpperCase() === 'DOC' 
                || args.rowData.nome_file.substring(args.rowData.nome_file.lastIndexOf('.')+1).toUpperCase() === 'DOCX') {
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmagineVideoAWSS3/${filePath}`, '', 'GET',
                    '', JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, retData => { 
                        this.dialogInstance.header = 'VISUALIZZAZIONE POLIZZA';    
                        this.dialogInstance.width = '65%';
                        this.dialogInstance.height = '90%';
                        this.dialogInstance.minHeight = '90%';
                        this.dialogInstance.close = (args) => {
                            document.getElementById(salvaChildGridId).ej2_instances[0].clearSelection();
                        };    
                        this.dialogInstance.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent');
                            const root = createRoot(container); 
                            root.unmount();
                        }     
                        this.dialogInstance.open = (args) => {
                            const container = document.getElementById('defaultDialogContent');
                            const root = createRoot(container);    
                            root.render(<CallbackWrapper callback={() => {
                                setTimeout(() => {
                                    const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData.signedURL)}&embedded=true`;
                                    const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData.signedURL)}`;
                                    const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData.filePath}`);
                                    const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                    document.getElementById(`IframeDocumento`).src = URLVisualGoogle;
                                    this.toggleBlocking();
                                }, 400);
                            }}>{this.otherDocumentRender()}</CallbackWrapper>);  
                        }    
                        this.dialogInstance.show(); 
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true});
                    this.toggleBlocking();
                }, true, false);
            } 
            if (args.rowData.nome_file.substring(args.rowData.nome_file.lastIndexOf('.')+1).toUpperCase() === 'JPG' 
                || args.rowData.nome_file.substring(args.rowData.nome_file.lastIndexOf('.')+1).toUpperCase() === 'JPEG') {
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmagineVideoAWSS3/${filePath}`, '', 'GET',
                '', JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, retData => { 
                    this.lightboxImage = retData.signedURL;
                    this.setState({showLightbox: true}); 
                    this.toggleBlocking(); 
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true});
                    this.toggleBlocking();
                }, true, false);
            }
        }     
    }
    onUploading(args) {
        args.currentRequest.setRequestHeader("Authorization", JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token);
    }
    onUploadSuccess(args) {
        const QueryExec = encodeURIComponent(`INSERT INTO [01005] (utente) VALUES ('${JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].username.toUpperCase()}'); SELECT MAX(id_file) AS ID from [01005] WHERE utente = '${JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].username.toUpperCase()}'`);                             
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'GET', QueryExec, '', retData => {
            if (aggiornaRevisione === false) {
                document.getElementById(`${salvaChildGridId}id_file`).ej2_instances[0].value = parseInt(retData[0].ID);
                document.getElementById(`${salvaChildGridId}id_file_padre`).ej2_instances[0].value = parseInt(retData[0].ID);
                document.getElementById(`${salvaChildGridId}nome_file`).ej2_instances[0].value += `_${parseInt(retData[0].ID).toString(16).toUpperCase()}.${args.file.name.substring(args.file.name.lastIndexOf('.')+1)}`;
                document.getElementById(`${salvaChildGridId}descrizione`).ej2_instances[0].value = args.file.name.substring(0, args.file.name.lastIndexOf('.'));
            }
            else {
                document.getElementById(`${salvaChildGridId}id_file`).ej2_instances[0].value = parseInt(retData[0].ID);
                document.getElementById(`${salvaChildGridId}rev`).ej2_instances[0].value = parseInt(document.getElementById(`${salvaChildGridId}rev`).ej2_instances[0].value) + 1;
                document.getElementById(`${salvaChildGridId}nome_file`).ej2_instances[0].value += `_${parseInt(retData[0].ID).toString(16).toUpperCase()}.${args.file.name.substring(args.file.name.lastIndexOf('.')+1)}`;
                document.getElementById(`${salvaChildGridId}descrizione`).ej2_instances[0].value = args.file.name.substring(0, args.file.name.lastIndexOf('.'));
            }
            setTimeout(() => {
                const filePath = `${CustomJS.CartellaProdTipiPolizzaACT}/${args.file.name}`.replaceAll('/', '[slash]');
                const filePathAWS = `${CustomJS.CartellaProdTipiPolizzaACT}/${document.getElementById(salvaChildGridId + 'nome_file').ej2_instances[0].value.replaceAll('/','\\')}`.replaceAll('/', '[slash]');
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(filePath)}/${encodeURIComponent(filePathAWS)}`, '', 'GET',
                    '', JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, retData2 => { 
                        document.getElementById(salvaChildGridId).ej2_instances[0].toolbarModule.enableItems([`${salvaChildGridId}_update`], true);
                        document.getElementById(`${salvaChildGridId}_update`).click();
                        document.getElementById(salvaChildGridId).ej2_instances[0].clearSelection();       
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, false); 
            }, 100);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    PDFViewerRender(fileNameDownload) {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <PdfViewerComponent id='PDFViewerPolizze' key={this.childKey} width="auto" height="750px" ref={pdf => this.PDFViewerInstance = pdf}  
                                                    enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false} downloadFileName={fileNameDownload}
                                                    serviceUrl={`${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                                </PdfViewerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    otherDocumentRender() {
        return(<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                                            <iframe id='IframeDocumento' title='Documenti' src='' style={{width: '950px', height: '750px', border: '1px solid black'}}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaCat' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <div>{this.state.showLightbox ? <Lightbox image={this.lightboxImage} onClose={()=>this.setState({showLightbox: false})}/>:null}</div> 
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog1" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance = dialog} isModal={true} height='90%' minHeight='90%' width='65%'>   
                            <div id='defaultDialogContent'></div>                         
                </DialogComponent> 
                <DialogComponent id="defaultDialog2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance2 = dialog} isModal={true} height='18%' minHeight='18%' width='20%'>   
                </DialogComponent> 
                <DialogComponent id="defaultDialog3" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance10 = dialog} isModal={true} height='90%' minHeight='90%' width='65%'>   
                            <div id='defaultDialogContent10'></div>                         
                </DialogComponent> 
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <div id='uploadPolizze'><UploaderComponent autoUpload={true} ref = {upload => {this.uploadObj = upload }} asyncSettings={this.asyncSettings} uploading={this.onUploading} 
                                                                        success={this.onUploadSuccess.bind(this)} allowedExtensions='.doc, .docx, .pdf, .jpeg, .jpg' multiple={false}/></div>
                                                <GridComponent id="GridTipiPolizze" ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true} actionBegin={this.actionBegin.bind(this)}
                                                    allowPaging={true} allowSorting={true} allowFiltering={true} filterSettings={this.filterSettings} enableAltRow={true} enablePersistence={true} childGrid={this.childGrid} hierarchyPrintMode='All'
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} sortSettings={this.sortSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_tipo_polizza' headerText='' isPrimaryKey='true'  visible={false} width='25%' textAlign='Center'headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='nome' headerText="Nome Polizza"  width='40%' validationRules={{ required: true }} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='modello' headerText="Modello"  width='22%'  headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='edizione' headerText="Edizione"  width='22%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_polizza_rifer' headerText="Riferimento"  width='25%' editType='DropDownEdit' edit={this.editTemplate} textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='note' headerText="Note" width='15%' headerTemplate={headerTemplate} edit={this.editTemplate2}></ColumnDirective>
                                                        <ColumnDirective field='preesistenza' headerText="Preesistenza"  width='20%' displayAsCheckBox={true} editType='booleanedit' textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='id_tipo_polizza_padre' headerText=""  width='15%' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='20%' commands={this.commands} textAlign='Right' allowFiltering={false}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // if (CustomJS.isNullEmpty(this.props.ArrivaDaDettSinistro))
        //     document.getElementById('titoloPagina').innerText = 'Tipi Polizza';
        document.getElementById('uploadPolizze').style.display = 'none';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '02001', retData => {
            let GridData1 = _.uniq(retData, elm => elm.id_tipo_polizza);
            let arrTemp = _.filter(retData, (elm) => {return !CustomJS.isNullEmpty(elm.id_tipo_polizza)});
            let GridData2 = _.map(arrTemp, (elm) => { return {id_file: elm.id_file, id_file_padre: elm.id_file_padre, rev: elm.rev, nome_file: elm.nome_file, descrizione: elm.descrizione, utente: elm.utente, host: elm.host, id_tipo_file: elm.id_tipo_file, id_tipo_polizza: elm.id_intabella, eliminato: elm.eliminato}});
            this.gridInstance.dataSource = GridData1;
            this.gridInstance.childGrid.dataSource = GridData2;
            this.gridInstance.refresh();   
            this.toggleBlocking();
            if (!CustomJS.isNullEmpty(this.props.DescrTipoPolizza))
                this.gridInstance.search(this.props.DescrTipoPolizza);            
            else
                this.gridInstance.search();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
    }
}