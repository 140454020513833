import React, { Component } from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined, template } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

let elemDdl = null, elemDdl2 = null;
const legenda = `0: Report inutilizzabile. Misure assenti o sbagliate, documentazione fotografica non presente o molto scarsa. Nel report ci sono riferimenti completamente errati ( numero di serie che non coincide, tipologia di bene errato.) 

1: Report con sole foto. Test strumentali assenti, scarsi e comunque non significativi. 

2: Sopralluogo standard. Sono presenti almeno le misure strumentali fondamentali. Il bene/componente è identificabile in modo corretto (marca, modello ed età). LA documentazione fotografica è presente ed è stata effettuata la verifica di preesistenza se richiesta.

3:Pratica particolarmente accurata: Sono presenti tutte le misure necessarie a valutare le singole componenti del bene .Il bene è stato testato funzionalmente, sono presenti nel report elementi che agevolano lo svolgimento della pratica in modo determinante.`;

export default class PaginaPerizie extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } }];
        this.DdlObj = null;
        this.filterSettings = { type: 'CheckBox' };
        this.searchSettings = { operator: 'contains', ignoreCase: true };
        this.pageSettings = { pageSize: 3 };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsDannoAccertato = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02020')))?.map(elm => { return { VALUE: elm.id_causa_accertata.toString(), TEXT: elm.descrizione } });
        this.dsDefinizione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02011')))?.filter(elm => { return ( CustomJS.isNullEmpty(elm.compagnie?.trim()) || elm.compagnie.indexOf(this.props.IdCompagnia) > -1 )}).map(elm => { return { VALUE: elm.id_tipo_indennizzo.toString(), TEXT: elm.descrizione } });
        this.dsValutazione = [{ VALUE: '1', TEXT: '1' }, { VALUE: '2', TEXT: '2' }, { VALUE: '3', TEXT: '3' }, { VALUE: '4', TEXT: '4' }];
        this.dsFulminazione = [{ VALUE: 'NO', TEXT: 'NO' }, { VALUE: 'SI', TEXT: 'SI' }];
        this.dsRivalsa = [{ VALUE: 'NO', TEXT: 'NO' }, { VALUE: 'SI', TEXT: 'SI' }];
        this.DdlObj = null;
        this.DdlObj2 = null;
        this.elemSelezValutaz = null;
        this.elemSelezBene = null;
        this.editTemplate = {
            create: () => { elemDdl = document.createElement('input'); return elemDdl },
            read: () => { return this.DdlObj.text },
            destroy: () => { this.DdlObj.destroy() },
            write: (args) => {
                this.elemSelezValutaz = args.rowData.valutazione; this.DdlObj = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Valutazone', popupHeight: '200px',
                    dataSource: this.dsValutazione, value: args.rowData.valutazione?.toString(),
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.valutazione = parseInt(args2.value);
                        this.elemSelezValutaz = parseInt(args2.value);
                    }
                }); this.DdlObj.appendTo(elemDdl)
            }
        };
        this.editTemplate2 = {
            create: () => { elemDdl2 = document.createElement('input'); return elemDdl2 },
            read: () => { return this.DdlObj2.text },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezBene = args.rowData.idBene; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Bene', popupHeight: '200px',
                    dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09009')))?.map(elm => { return { VALUE: elm.ID.toString(), TEXT: elm.bene } }), value: args.rowData.idBene?.toString(),
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.idBene = parseInt(args2.value);
                        this.elemSelezBene = parseInt(args2.value);
                    }
                }); this.DdlObj2.appendTo(elemDdl2)
            }
        };
    }
    state = {
        blocking: false,
        dsValutazioneCat: [],
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    checkBeneAltroBene(args) {
        return !CustomJS.isNullEmpty(document.getElementById('GridValutazCatPeriziedescrBene').ej2_instances[0].value)
            || !CustomJS.isNullEmpty(document.getElementById('GridValutazCatPeriziealtroBene').ej2_instances[0].value?.trim())
    }
    actionBegin(args) {
        if (args.action === "edit" && args.requestType === "save") {
            args.data.valutazione = this.elemSelezValutaz;
            args.data.idBene = this.elemSelezBene;
        }
    }
    actionComplete(args) {
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione = args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '010041';
            const dataObj = {
                IdValutazione: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_valutazione : args.data[0].id_valutazione,
                IdCat: this.props.idCat,
                Valutazione: args.rowData?.valutazione,
                Bene: args.rowData?.idBene,
                AltroBene: CustomJS.isNullEmpty(args.data[0]) ? args.data.altroBene : args.data[0].altroBene,
                NumeroSerialeBene: CustomJS.isNullEmpty(args.data[0]) ? args.data.numero_seriale_bene : args.data[0].numero_seriale_bene,
                Gfk: CustomJS.isNullEmpty(args.data[0]) ? args.data.gfk : args.data[0].gfk,
                ValoreBeneNuovo: CustomJS.isNullEmpty(args.data[0]) ? args.data.valore_bene_nuovo : args.data[0].valore_bene_nuovo
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                    if (retData.response === 'OK') {
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
            if (args.action === "add" && args.requestType === "save") {
                if (args.data.altroBene || (args.data[0] && args.data[0].altroBene)) {
                    const QueryExec1 = `SELECT [09010].descr_altro_bene FROM [09010]`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'GET', QueryExec1, '', retData => {
                        let altroBeneEsistente = false;
                        for (let i in retData) {
                            const descrizione = retData[i].descr_altro_bene?.trim()?.toUpperCase();
                            const altroBene = CustomJS.isNullEmpty(args.data[0]) ? args.data.altroBene?.trim()?.toUpperCase() : args.data[0].altroBene?.trim()?.toUpperCase();
                            if (descrizione === altroBene) {
                                altroBeneEsistente = true;
                                break;
                            }
                        }
                        if (altroBeneEsistente === false){
                            const dataObj = {
                                DescrizioneAltroBene: CustomJS.isNullEmpty(args.data[0]) ? args.data.altroBene : args.data[0].altroBene,
                            };
                            const QueryExec = "INSERT INTO [09010] (descr_altro_bene) VALUES (@descr_altro_bene)";
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            }, true, true);
                        }
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    }, true, true);
                }
                setTimeout(() => {
                    this.caricaDati();
                }, 200);
            }
        }
    }
    onChangeSchedeCompilate(args) {
        this.aggiornaDettaglio('numero_schede', args.value, 'INT');
    }
    onChangeRichiestaIndennizzo(args) {
        this.aggiornaDettaglio('v_richiesta_indennizzo', args.value, 'FLOAT');
    }
    onChangeTotNuovo(args) {
        this.aggiornaDettaglio('v_totale_nuovo', args.value, 'FLOAT');
    }
    onChangeTotEquiv(args) {
        this.aggiornaDettaglio('v_totale_equivalente', args.value, 'FLOAT');
    }
    onChangeFranchigia(args) {
        this.aggiornaDettaglio('v_franchigia', args.value, 'FLOAT');
    }
    onChangeStimaDanno(args) {
        this.aggiornaDettaglio('v_stima_danno', args.value, 'FLOAT');
    }
    onChangeDannoAccertato(args) {
        this.aggiornaDettaglio('id_tipo_danno_risc', args.value, 'INT');
    }
    onChangeDefinizione(args) {
        this.aggiornaDettaglio('id_tipo_indennizzo', args.value, 'INT');
    }
    onChangeCommenti(args) {
        this.aggiornaDettaglio('commenti_valutazioni', args.value, 'CHAR', '[01004]');
    }
    onChangeFulminazione(args) {
        this.aggiornaDettaglio('fulminazione', args.value, 'CHAR');
    }
    onChangeRivalsa(args) {
        this.aggiornaDettaglio('rivalsa', args.value, 'CHAR');
    }
    toolbarClick(args) {
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPaginaPerizie' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <input type="hidden" id='id_perizia' />
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-content">
                                        <div className='row'></div>
                                        <div className='row'>
                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                <TextBoxComponent id='id_interno_sinistro' floatLabelType='Always' placeholder='ID Sinistro' cssClass='e-outline' value={this.props.IdSinistro} readOnly={true}></TextBoxComponent>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                <DropDownListComponent id='fulminazione' fields={this.ddlFields} ref={ddlObj => this.ddlInstance3 = ddlObj} dataSource={this.dsFulminazione}
                                                    allowFiltering={false} filterType='Contains' showClearButton={false} change={this.onChangeFulminazione.bind(this)}
                                                    placeholder="Fulminazione" floatLabelType='Auto' popupHeight="500px" cssClass='e-outline'>
                                                </DropDownListComponent>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                <DropDownListComponent id='rivalsa' fields={this.ddlFields} ref={ddlObj => this.ddlInstance4 = ddlObj} dataSource={this.dsRivalsa}
                                                    allowFiltering={false} filterType='Contains' showClearButton={false} change={this.onChangeRivalsa.bind(this)}
                                                    placeholder="Rivalsa" floatLabelType='Auto' popupHeight="500px" cssClass='e-outline'>
                                                </DropDownListComponent>
                                            </div>
                                            <div className="col-xs-2 col-sm-3 col-lg-3 col-md-3">
                                                <NumericTextBoxComponent id='n_schede_compilate' floatLabelType='Always' placeholder='N° Schede Compilate' cssClass='e-outline' blur={this.onChangeSchedeCompilate.bind(this)} change={this.onChangeSchedeCompilate.bind(this)} format='N2' decimals='2'></NumericTextBoxComponent>
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                <NumericTextBoxComponent id='richiesta_indennizzo' floatLabelType='Always' placeholder='Richiesta Indennizzo' cssClass='e-outline' blur={this.onChangeRichiestaIndennizzo.bind(this)} change={this.onChangeRichiestaIndennizzo.bind(this)} format='N2' decimals='2' readOnly={true} value={this.props.richiestaIndennizzo}></NumericTextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <DropDownListComponent id='danno_accertato' fields={this.ddlFields} ref={ddlObj => this.ddlInstance1 = ddlObj} dataSource={this.dsDannoAccertato}
                                                    allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeDannoAccertato.bind(this)}
                                                    placeholder="Danno Accertato" floatLabelType='Auto' popupHeight="500px" cssClass='e-outline'>
                                                </DropDownListComponent>
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                <NumericTextBoxComponent id='v_tot_nuovo' floatLabelType='Always' placeholder='Valore Totale a Nuovo' cssClass='e-outline' blur={this.onChangeTotNuovo.bind(this)} change={this.onChangeTotNuovo.bind(this)} format='N2' decimals='2'></NumericTextBoxComponent>
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                <NumericTextBoxComponent id='v_tot_equiv' floatLabelType='Always' placeholder='Valore Totale Equivalente' cssClass='e-outline' blur={this.onChangeTotEquiv.bind(this)} change={this.onChangeTotEquiv.bind(this)} format='N2' decimals='2'></NumericTextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                <NumericTextBoxComponent id='franchigia' floatLabelType='Always' placeholder='Franchigia' cssClass='e-outline' blur={this.onChangeFranchigia.bind(this)} change={this.onChangeFranchigia.bind(this)} format='N2' decimals='2'></NumericTextBoxComponent>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                <NumericTextBoxComponent id='stima_danno' floatLabelType='Always' placeholder='Stima del Danno' cssClass='e-outline' blur={this.onChangeStimaDanno.bind(this)} change={this.onChangeStimaDanno.bind(this)} format='N2' decimals='2'></NumericTextBoxComponent>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                <DropDownListComponent id='definizione' fields={this.ddlFields} ref={ddlObj => this.ddlInstance2 = ddlObj} dataSource={this.dsDefinizione}
                                                    allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeDefinizione.bind(this)}
                                                    placeholder="Definizione" floatLabelType='Auto' popupHeight="600px" cssClass='e-outline'>
                                                </DropDownListComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="control-section card-control-section basic_card_layout" style={{ marginTop: '20px' }}>
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-content">
                                        <div className='row'></div>
                                        <div className='row'></div>
                                        <div className='row'></div>
                                        <div className='row'>
                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                <TextBoxComponent id='valutazioneCat' floatLabelType='Always' placeholder='Valutazione CAT' cssClass='e-outline' value={this.props.cat} readOnly={true}></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                                                <TextBoxComponent id='commenti_valutazioni' floatLabelType='Always' placeholder='Commenti' cssClass='e-outline' multiline={true} ref={txtObj => { this.textBoxInstance2 = txtObj }} blur={this.onChangeCommenti.bind(this)}></TextBoxComponent>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" style={{ whiteSpace: 'pre-line', backgroundColor: '#98FB98', height: '360px' }}>
                                                <TextBoxComponent id='legenda' floatLabelType='Always' placeholder='Legenda Valutazione Tecnica' cssClass='e-outline' multiline={true} ref={txtObj => { this.textBoxInstance1 = txtObj }} readOnly={true}></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div id='GridValutazCatPerizieNasc' className="col-xs-10 offset-xs-1 col-sm-10 offset-sm-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                                                {/* TODO VERIFICARE IL MOTIVO PER CUI IL VALIDATOR "checkValidBene" NON FUNZIONA */}
                                                <GridComponent id="GridValutazCatPerizie" ref={grid => this.gridInstance = grid} dataSource={this.state.dsValutazioneCat} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} actionComplete={this.actionComplete.bind(this)} actionBegin={this.actionBegin.bind(this)}
                                                    toolbarClick={this.toolbarClick.bind(this)} toolbar={this.toolbarOptions} editSettings={this.editSettings} style={{ marginRight: '5px' }} height='200px' pageSettings={this.pageSettings} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_valutazione' isPrimaryKey={true} headerText="ID Valutazione" visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='valutazione' headerText="Valutazione" width='6%' edit={this.editTemplate} editType='DropDownEdit' textAlign='Center' headerTemplate={headerTemplate} validationRules={{ required: true }}></ColumnDirective>
                                                        <ColumnDirective field='descrBene' headerText="Bene" width='23%' edit={this.editTemplate2} editType='DropDownEdit' textAlign='Center' headerTemplate={headerTemplate} validationRules={{ checkValidBene: [this.checkBeneAltroBene, 'Bene oppure Altro Bene sono obbligatori'] }}></ColumnDirective>
                                                        <ColumnDirective field='altroBene' headerText="Altro Bene" width='23%' textAlign='Center' headerTemplate={headerTemplate} validationRules={{ checkValidBene: [this.checkBeneAltroBene, 'Bene oppure Altro Bene sono obbligatori'] }}></ColumnDirective>
                                                        <ColumnDirective field='numero_seriale_bene' headerText="S/N" width='23%' textAlign='Center' headerTemplate={headerTemplate} validationRules={{ maxLength: 50 }}></ColumnDirective>
                                                        <ColumnDirective field='gfk' headerText="GFK" width='5%' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                                                        <ColumnDirective field='valore_bene_nuovo' headerText="Valore Bene a Nuovo" width='15%' textAlign='Center' headerTemplate={headerTemplate} validationRules={{ required: true }} editType='NumericEdit' format='N2'></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='5%' commands={this.commands} textAlign='Right' headerTemplate={headerTemplate}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Perizie';
        this.caricaDati();
        this.textBoxInstance1.addAttributes({ rows: 16 });
        document.getElementById('legenda').ej2_instances[0].value = legenda;
        this.textBoxInstance2.addAttributes({ rows: 5 });
        if (CustomJS.isNullEmpty(this.props.idCat)) {
            document.getElementById('GridValutazCatPerizie').ej2_instances[0].element.classList.add('disablegrid');
            document.getElementById('GridValutazCatPerizieNasc').classList.add('wrapper');
            document.getElementById('commenti_valutazioni').ej2_instances[0].readOnly = true;
        }
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        const QueryExec = `SELECT [01002].* FROM [01002] WHERE [01002].id_sinistro = ${this.props.idSin} AND revocata = 0; SELECT [01004].commenti_valutazioni FROM [01004] WHERE [01004].id_sinistro = ${this.props.idSin}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                document.getElementById('fulminazione').ej2_instances[0].value = retData[0][0].fulminazione?.trim();
                document.getElementById('rivalsa').ej2_instances[0].value = retData[0][0].rivalsa?.trim();
                document.getElementById('n_schede_compilate').ej2_instances[0].value = retData[0][0].numero_schede;
                document.getElementById('v_tot_nuovo').ej2_instances[0].value = retData[0][0].v_totale_nuovo;
                document.getElementById('v_tot_equiv').ej2_instances[0].value = retData[0][0].v_totale_equivalente;
                document.getElementById('franchigia').ej2_instances[0].value = retData[0][0].v_franchigia;
                document.getElementById('stima_danno').ej2_instances[0].value = retData[0][0].v_stima_danno;
                document.getElementById('danno_accertato').ej2_instances[0].value = retData[0][0].id_tipo_danno_risc?.toString();
                document.getElementById('definizione').ej2_instances[0].value = retData[0][0].id_tipo_indennizzo?.toString();
                document.getElementById('id_perizia').value = retData[0][0].id_perizia;
                document.getElementById('commenti_valutazioni').ej2_instances[0].value = CustomJS.isNullEmpty(retData[1]) ? '' : retData[1][0]?.commenti_valutazioni;
            }
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
        setTimeout(() => {
            if (CustomJS.isNullEmpty(this.props.idCat))
                return;
            const QueryExec = `SELECT [010041].*, [09009].bene AS descr_bene FROM [010041] LEFT JOIN [09009] ON [010041].bene = [09009].ID WHERE [010041].id_cat = ${this.props.idCat}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'GET', QueryExec, '', retData => {
                this.dsValutazioneCat = retData.map(elm => { return { id_cat: elm.id_cat, id_valutazione: elm.id_valutazione, valutazione: elm.valutazione, idBene: elm.bene, descrBene: elm.descr_bene, altroBene: elm.altroBene, numero_seriale_bene: elm.numero_seriale_bene, valore_bene_nuovo: elm.valore_bene_nuovo, gfk: elm.gfk } });
                document.getElementById(`GridValutazCatPerizie`).ej2_instances[0].dataSource = this.dsValutazioneCat;
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, true, true);
        }, 500);
    }
    aggiornaDettaglio(nomeCampo, valoreCampo, tipoCampo, tabella) {
        tabella = CustomJS.isNullEmpty(tabella) ? '[01002]' : tabella;
        const dataObj = {
            IdSinistro: this.props.idSin,
            ValoreCampo: valoreCampo,
            TipoCampo: tipoCampo,
            IdPerizia: document.getElementById('id_perizia').value,
            IdCat: this.props.idCat
        };
        let condizione = tabella === '[01002]' ? 'id_perizia = @id_perizia' : tabella === '[01004]' ? 'id_cat = @id_cat' : '';
        const QueryExec = `UPDATE ${tabella} SET ${nomeCampo} = @valore_campo WHERE ${condizione}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData2 => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
}