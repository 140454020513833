/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import RegioniProvince1 from '../assets/RegioniProvince1.json';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
let elemDdl = null;
export default class PaginaCat extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettings = {pageSize : 10, pageCount : 10};
        this.filterSettings = { type: 'CheckBox'};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.DdlObj = null;
        this.elemSelezProvincia = null;
        this.txtObj1 = null;
        this.editTemplate = { create: () => { elemDdl = document.createElement('input'); return elemDdl},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.elemSelezProvincia = args.rowData.provincia; this.DdlObj = new DropDownList({fields:{text: 'TEXT' , value: 'VALUE'}, placeholder:'Provincia', popupHeight:'200px', 
                                                dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1'))), value: args.rowData.provincia, allowFiltering: true, filterType: 'Contains', floatLabelType: 'Auto', 
                              change: (args2) => {args.rowData.provincia = args2.value; this.elemSelezProvincia = args2.value;} }); this.DdlObj.appendTo(elemDdl)}};
        this.editTemplate2 = { create: () => { let elemTXT = document.createElement('input'); return elemTXT },
                              read: () => {return this.txtObj1.value},
                              destroy: () => {this.txtObj1.destroy()},
                              write: (args) => { this.txtObj1 = new TextBox({ value: args.rowData.note_generiche, floatLabelType: 'Auto', placeholder: 'Note', multiline: true,
                                                  htmlAttributes: {rows: 5} }); this.txtObj1.appendTo(args.element) } };  
        this.childGrid = {
            queryString: 'id_collaboratore',
            editSettings: this.editSettings,
            allowPaging: false,
            allowSelection: true,
            enableHover: true,
            showDeleteConfirmDialog: true,
            allowTextWrap: true,
            allowSorting: false, 
            allowFiltering: false,
            enableAltRow: true, 
            enablePersistence: false,
            actionComplete: this.actionComplete2.bind(this),
            pageSettings: { pageSize: 15, pageCount: 15 },
            toolbar: [ 'Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport' ],
            columns: [
                { field: 'id_collaboratore', visible: false },
                { field: 'id', visible: false, isPrimaryKey: true},
                { field: 'nominativo', headerText: 'Nominativo ', textAlign: 'Center', headerTemplate: headerTemplate , validationRules: {required: true}},
                { field: 'telefono', headerText: 'Telefono', textAlign: 'Center', headerTemplate: headerTemplate },
                { field: 'Comandi', headerText:"", commands: this.commands, textAlign:'Right', headerTemplate: headerTemplate },
            ], 
            actionBegin: this.actionBegin2
        };
    }
    state = {
        blocking: false,
        dsCat: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {       
    }
    actionBegin(args) {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') 
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'user_sito' || elm.field === 'password_sito' || elm.field === 'telefono2' || elm.field === 'email2') 
                    elm.visible = true;
            }); 
    }
    actionBegin2(args){
        if(args.requestType === 'add') {           
            args.data.id_collaboratore = this.parentDetails.parentRowData.id_collaboratore;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.height = '80%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo CAT' : `Dettaglio di ${args.rowData.ragione_sociale}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '00006';
            const dataObj = {
                IdCollaboratore: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_collaboratore : args.data[0].id_collaboratore,
                RagioneSociale: CustomJS.isNullEmpty(args.data[0]) ? args.data.ragione_sociale : args.data[0].ragione_sociale,
                PIVA: CustomJS.isNullEmpty(args.data[0]) ? args.data.p_iva : args.data[0].p_iva,
                CodiceFiscale: CustomJS.isNullEmpty(args.data[0]) ? args.data.codice_fiscale : args.data[0].codice_fiscale,
                Indirizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.indirizzo : args.data[0].indirizzo,
                Citta: CustomJS.isNullEmpty(args.data[0]) ? args.data.citta : args.data[0].citta,
                Provincia: args.rowData?.provincia,
                CAP: CustomJS.isNullEmpty(args.data[0]) ? args.data.cap : args.data[0].cap,
                Telefono1: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                Telefono3: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono3 : args.data[0].telefono3,
                Email1: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Email2: CustomJS.isNullEmpty(args.data[0]) ? args.data.email2 : args.data[0].email2,
                Email3: CustomJS.isNullEmpty(args.data[0]) ? args.data.email3 : args.data[0].email3,
                NoteGeneriche: CustomJS.isNullEmpty(args.data[0]) ? args.data.note_generiche : args.data[0].note_generiche,
                UtenteJFCAT: CustomJS.isNullEmpty(args.data[0]) ? args.data.user_sito : args.data[0].user_sito,
                PasswordJFCAT: CustomJS.isNullEmpty(args.data[0]) ? args.data.password_sito : args.data[0].password_sito,
                Fisso: CustomJS.isNullEmpty(args.data[0]) ? args.data.fisso : args.data[0].fisso,
                Attivo: CustomJS.isNullEmpty(args.data[0]) ? args.data.attivo : args.data[0].attivo
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }); 
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200); 
                        this.gridInstance.columns.forEach(elm => {
                            if (elm.field === 'user_sito' || elm.field === 'password_sito') 
                                elm.visible = false;
                        });
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
        if (args.requestType === 'cancel')
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'user_sito' || elm.field === 'password_sito') 
                    elm.visible = false;
            }); 
    }
    actionComplete2(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo contatto' : `Dettaglio di ${args.rowData.nominativo}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '000061';
            const dataObj = {
                IdCollaboratore: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_collaboratore : args.data[0].id_collaboratore,
                IdContatto: CustomJS.isNullEmpty(args.data[0]) ? args.data.id : args.data[0].id,
                NominativoContatto: CustomJS.isNullEmpty(args.data[0]) ? args.data.nominativo : args.data[0].nominativo,
                TelefonoContatto: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono : args.data[0].telefono,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => { 
                    console.log(retData);                   
                    if (retData.response === 'KO') {                       
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });  
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);   
                    }                         
            }, error => {                
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaCat' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridCAT" dataSource={this.state.dsCat} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true} actionBegin={this.actionBegin.bind(this)}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} childGrid={this.childGrid} hierarchyPrintMode='All'
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} filterSettings={this.filterSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_collaboratore' headerText='ID Collaboratore' isPrimaryKey='true' visible={false} width='25%' textAlign='Center' headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='ragione_sociale' headerText="Ragione sociale"  width='30%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='piva' headerText="P.IVA"  width='22%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='codice_fiscale' headerText="Codice Fiscale"  width='22%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='indirizzo' headerText="Indirizzo"  width='25%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='citta' headerText="Città"  width='15%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_provincia' headerText="Provincia"  width='20%'  textAlign='Center' headerTemplate={headerTemplate} edit={this.editTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cap' headerText="CAP"  width='15%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono1' headerText="Telefono1"  width='22%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono2' headerText="Telefono2"  width='10%' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email1' headerText="Email1"  width='30%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email2' headerText="Email2"  width='30%'  textAlign='Center' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email3' headerText="Email3"  width='25%' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='note_generiche' headerText="Note"  width='30%'  textAlign='Center' headerTemplate={headerTemplate} edit={this.editTemplate2}></ColumnDirective>
                                                        <ColumnDirective field='user_sito' headerText="Utente Jellyfish CAT Mobile"  textAlign='Center' headerTemplate={headerTemplate} visible={false}></ColumnDirective>
                                                        <ColumnDirective field='password_sito' headerText="Password Jellyfish CAT Mobile"  textAlign='Center' headerTemplate={headerTemplate} visible={false}></ColumnDirective>
                                                        <ColumnDirective field='fisso' headerText="Fisso"  width='15%' format='N2' editType='NumericEdit' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='attivo' headerText="Attivo"  width='15%' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='20%' commands={this.commands} textAlign='Right' allowFiltering={false}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'CAT';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '00006', retData => {
            let GridData1 = _.uniq(retData, elm => elm.id_collaboratore);
            let arrTemp = _.filter(retData, (elm) => {return !CustomJS.isNullEmpty(elm.id_contatto)});
            let GridData2 = _.map(arrTemp, (elm) => { return {id_collaboratore: elm.id_collaboratore, id: elm.id_contatto, nominativo: elm.nominativo_contatto, telefono: elm.telefono_contatto}});
            GridData1.forEach(elm => {
                let indOK = 0;
                let objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia ? idx : 0;  return elm3 === elm.provincia; })) })
                elm.descr_provincia = objReg?.capoluoghi[indOK];
            })
            this.gridInstance.dataSource = GridData1;
            this.gridInstance.childGrid.dataSource = GridData2;
            this.gridInstance.refresh();   
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
    }
}