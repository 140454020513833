/* eslint-disable default-case */
import React, { Component } from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

export default class PaginaResetSinistri extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Search', 'ExcelExport'];        
        this.commands = [{ type: "Reset", buttonOption: { iconCss: "fas fa-redo fa-10x", cssClass: "e-details" }}];
        this.DdlObj = null;
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.searchSettings = {operator: 'contains' , ignoreCase: true};
        this.filterSettings = { type: 'CheckBox'};  
        this.groupSettings = { showGroupedColumn: true };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsStato = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02004'))).filter(elm => { return !CustomJS.isNullEmpty(elm.id_tipo_stato)}).map(elm => { return { VALUE: elm.id_tipo_stato.toString(), TEXT: elm.descrizione} });
        this.salvaSel = {};
        this.dialogButtons1 = [{
            click: () => {
                this.dialogInstance1.hide();
                this.forzaStato();
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            },
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
            }];
        this.dialogButtons2 = [{
            click: () => {
                this.dialogInstance1.hide();
                this.resettaSinistro();
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            },
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
    }
    state = {
        blocking: false,
        dsSinistri: [],
        showDialog1: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '600px';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Liquidatore' : `Dettaglio di ${args.rowData.ragione_sociale}`;
            //TODO Trovare il modo di mettere il campo note come multiline. Così non funziona
            //args.dialog.element.querySelectorAll('input')[5].ej2_instances[0].multiline = true;
            //args.dialog.element.querySelectorAll('input')[5].ej2_instances[0].addAttributes({ rows: 4 }); 
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            debugger;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '00009';
            const dataObj = {
                IdLiquidatore: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_liquidatore : args.data[0].id_liquidatore,
                RagioneSociale: CustomJS.isNullEmpty(args.data[0]) ? args.data.ragione_sociale : args.data[0].ragione_sociale,
                Indirizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.indirizzo : args.data[0].indirizzo,
                Citta: CustomJS.isNullEmpty(args.data[0]) ? args.data.citta : args.data[0].citta,
                Provincia: CustomJS.isNullEmpty(args.data[0]) ? args.data.provincia : args.data[0].provincia,
                CAP: CustomJS.isNullEmpty(args.data[0]) ? args.data.cap : args.data[0].cap,
                Telefono1: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                Email1: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Email2: CustomJS.isNullEmpty(args.data[0]) ? args.data.email2 : args.data[0].email2,
                Note: CustomJS.isNullEmpty(args.data[0]) ? args.data.note : args.data[0].note
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200); 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    resettaSinistro(){
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        const dataObj = {
            IdInternoSinistro : this.salvaSel.id_interno_sinistro,
            Utente: JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].username.toUpperCase(),
            DescrNota: `Sinistro resettato`,
        };

        const QueryExec = encodeURIComponent("BEGIN TRAN; INSERT INTO [01003] VALUES (@id_interno_sinistro, null, @descr_nota"  +
                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null); "+
                        "UPDATE [01001] SET [01001].id_compagnia = Null, [01001].id_divisione = Null, [01001].id_agenzia = Null, [01001].numero_polizza = Null,"+
                        " [01001].id_polizza = Null, [01001].numero_sinistro_agenzia = Null WHERE [01001].id_interno_sinistro= @id_interno_sinistro; COMMIT;");

        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            toast.success('Sinistro resettato con successo', {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    forzaStato(){
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        const dataObj = {
            IdInternoSinistro : this.gridInstance.getSelectedRecords()[0].id_interno_sinistro,
            Utente: JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).data[0].username.toUpperCase(),
            StatoNuovo: parseInt(this.ddlInstance.value),
            DescrNota: `Stato forzato da -${this.gridInstance.getSelectedRecords()[0].descrizione}- a -${this.ddlInstance.text}-`,
        };
        const QueryExec = encodeURIComponent("BEGIN TRAN; INSERT INTO [01003] VALUES (@id_interno_sinistro, null, @descr_nota"  +
                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null); "+
                        " UPDATE [01001] SET [01001].id_stato = @stato_nuovo WHERE [01001].id_interno_sinistro= @id_interno_sinistro; COMMIT;");

        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            toast.success('Stato forzato con successo', {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
            this.caricaDati();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    btnForzaStato(args) {
        if(this.gridInstance.getSelectedRecords().length === 0 || CustomJS.isNullEmpty(this.ddlInstance.value))
            return;
        this.dialogInstance1.buttons = this.dialogButtons1;
        this.dialogInstance1.content = `Forzare lo stato del sinistro <span style='font-size:17px;font-weight:bolder'>${this.gridInstance.getSelectedRecords()[0].id_interno_sinistro}</span>? Verrà inserita una nota di sistema.`;
        this.dialogInstance1.width = '35%';
        this.dialogInstance1.height = '180px';
        this.dialogInstance1.minHeight = '180px';
        this.dialogInstance1.show();
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-redo') > -1) {  
            this.dialogInstance1.buttons = this.dialogButtons2;
            this.dialogInstance1.content = `Si vuole resettare l'incarico con ID <span style='font-size:17px;font-weight:bolder'>${args.rowData.id_interno_sinistro}</span>. Confermare il reset dell'incarico?`;
            this.dialogInstance1.height = '170px';
            this.dialogInstance1.minHeight = '170px';
            this.dialogInstance1.width = '35%';           
            this.dialogInstance1.show();   
            this.salvaSel = args.rowData;           
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaResetSinistri' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='35%'
                    ref={dialog => this.dialogInstance1 = dialog} header='Attenzione!!' buttons={this.dialogButtons1} height='180px' minHeight='180px' isModal={true} 
                    content=''>
                </DialogComponent> 
                <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content"> 
                                    {/* <div className='row'></div> 
                                    <div className='row'></div>
                                    <div className='row'></div>   */}
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-2 col-lg-3 col-md-3" style={{ marginLeft: '50px', marginRight: '5px'}}>                                    
                                            <DropDownListComponent id='ddlStato' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance = ddlObj } dataSource={this.dsStato} 
                                                allowFiltering={true} filterType='Contains' width='15vw' 
                                                placeholder="Selezionare uno Stato da forzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'> 
                                            </DropDownListComponent> 
                                        </div>
                                        <div className="col-xs-12 col-sm-2 col-lg-2 col-md-2">
                                            <ButtonComponent id='BtnForzaStato' alt='Forza Stato' onClick={this.btnForzaStato.bind(this)} cssClass='e-info' style={{fontWeight: '700'}}>Forza Stato</ButtonComponent>
                                        </div>
                                    </div>
                                    <div className='row'>
                                       <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <GridComponent id="GridResetSinistri" ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true} enablePersistence={true}
                                                allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} filterSettings={this.filterSettings} groupSettings={this.groupSettings} 
                                                actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} pageSettings={this.pageSettings} searchSettings={this.searchSettings} commandClick={this.onCommandClick.bind(this)}
                                                toolbar={this.toolbarOptions} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='id_interno_sinistro' headerText='ID interno sinistro' isPrimaryKey='true' width='30%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                    <ColumnDirective field='numero_sinistro_agenzia' headerText="Numero sinistro agenzia" width='30%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='descrizione' headerText="Stato" width='30%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                </ColumnsDirective>
                                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                            </GridComponent>
                                        </div>
                                    </div>                        
                                </div>                              
                            </div>
                        </div> 
                    </div>
                </div>
               </div>
               </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Reset Sinistri';
        this.caricaDati();
    }
    caricaDati(){
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        const QueryExec = encodeURIComponent("SELECT [01001].*, [02004].descrizione FROM [01001] INNER JOIN [02004] ON [01001].id_stato = [02004].id_tipo_stato");
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'GET', QueryExec, '', retData => {
            let arrTemp = _.filter(retData, (elm) => {return !CustomJS.isNullEmpty(elm.id_interno_sinistro)});
            let GridData1 = _.map(arrTemp, (elm) => { return {id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, descrizione: elm.descrizione,}});
            this.gridInstance.dataSource = GridData1;
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
}