/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class PaginaModelliEmailStati extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.editSettings2 = { allowAdding: true, allowEditing: false, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.pageSettings = {pageSize : 10, pageCount : 10};
        this.filterSettings = { type: 'CheckBox'};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.commands2 = [{ type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.editTemplate3 = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                               read: () => {return this.DdlObj2.text},
                               destroy: () => {this.DdlObj2.destroy()},
                               write: (args) => {this.DdlObj2 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Stato', popupHeight:'300px', floatLabelType: 'Auto', 
                                                 dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02004'))).map(elm => {return {VALUE: elm.id_tipo_stato.toString(), TEXT: elm.descrizione}}), 
                                                 value: args.rowData.id_stato?.toString(),
                                                 allowFiltering: true, filterType: 'Contains',
                                                 change: (args2) => {args.rowData.id_stato = parseInt(args2.value)} }); this.DdlObj2.appendTo(args.element) }};
        this.childGrid = {
            queryString: 'id_email',
            editSettings: this.editSettings2,
            allowPaging: false,
            allowSelection: true,
            enableHover: true,
            showDeleteConfirmDialog: true,
            allowTextWrap: true,
            allowSorting: false, 
            allowFiltering: false,
            enableAltRow: true, 
            enablePersistence: false,
            pageSettings: { pageSize: 15, pageCount: 15 },
            toolbar: [ 'Add', 'Delete','Update', 'Cancel', 'Search'],
            columns: [
                { field: 'id_email_stato', isPrimaryKey: true, visible: false },
                { field: 'descr_stato', headerText: 'Stato ', textAlign: 'Center', headerTemplate: headerTemplate , validationRules: { required: true, checkDuplicate: [this.checkDuplicateStato, 'Stato già presente'] }, editType: 'DropDownEdit', edit: this.editTemplate3 },
                { field: 'sollecito', headerText: 'Sollecito', displayAsCheckBox: true, editType: 'booleanedit', textAlign: 'Center', headerTemplate: headerTemplate },
                { field: 'Comandi', headerText:"", commands: this.commands2, textAlign:'Right', headerTemplate: headerTemplate },
            ], 
            actionBegin: this.onActionBegin2,
            actionComplete: this.onActionComplete2.bind(this),
        };
        this.DdlObj1 = null;
        this.elemSelezTipoMess = null;
        this.DdlObj2 = null;
        this.txtObj1 = null;
        this.MslObj1 = null;
        this.MslObj2 = null;
        this.MslObj3 = null;
        this.dsMslObj = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02007')))?.map(elm => { return { VALUE: elm.id_tipo_dest.toString(), TEXT: elm.descrizione, campo_id: elm.campo_id } });
        this.editTemplate1 = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                               read: () => {return this.DdlObj1.text},
                               destroy: () => {this.DdlObj1.destroy()},
                               write: (args) => {this.elemSelezTipoMess = args.rowData.id_tipo_messaggio; this.DdlObj1 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Tipo Messaggio', popupHeight:'200px', floatLabelType: 'Auto',
                                                dataSource:JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02009'))).map(elm => {return {VALUE: elm.id_tipo_mess.toString(), TEXT: elm.descrizione}}), 
                                                value: args.rowData.id_tipo_messaggio?.toString(),
                                                change: (args2) => {
                                                    args.rowData.id_tipo_messaggio = parseInt(args2.value);
                                                    this.elemSelezTipoMess = parseInt(args2.value);
                                                } }); this.DdlObj1.appendTo(args.element) }};   
        this.editTemplate2 = { create: () => { let elemTXT = document.createElement('input'); return elemTXT },
                               read: () => {return this.txtObj1.value},
                               destroy: () => {this.txtObj1.destroy()},
                               write: (args) => { this.txtObj1 = new TextBox({ value: args.rowData.testo, floatLabelType: 'Auto', placeholder: 'Testo', multiline: true,
                                                  htmlAttributes: {rows: 10} }); this.txtObj1.appendTo(args.element) } }; 
        this.editTemplate4 = { create: () => { let elemMsl = document.createElement('input'); return elemMsl},
                               read: () => { let strRet = '  '; this.MslObj1.value.forEach(elm => { strRet += _.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm }).campo_id + ' '; }); return strRet; },
                               destroy: () => {this.MslObj1.destroy()},
                               write: (args) => { let arrTmp = CustomJS.isNullEmpty(args.rowData.destinataria) ? [] : args.rowData.destinataria.trim().split(' '); let arrRet = []; 
                                                  arrTmp.forEach(elm => { arrRet.push(_.find(this.dsMslObj, elm2 => { return elm2.campo_id === elm }).VALUE); });
                                                  this.MslObj1 = new MultiSelect({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Destinatari', floatLabelType: 'Auto', popupHeight:'200px', mode: 'Box',
                                                                                  dataSource: this.dsMslObj, tagging: this.onTagging.bind(this), value: arrRet }); 
                                                  this.MslObj1.appendTo(args.element) }};  
        this.editTemplate5 = { create: () => { let elemMsl = document.createElement('input'); return elemMsl},
                               read: () => { let strRet = '  '; this.MslObj2.value.forEach(elm => { strRet += _.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm }).campo_id + ' '; }); return strRet; },
                               destroy: () => {this.MslObj2.destroy()},
                               write: (args) => { let arrTmp = CustomJS.isNullEmpty(args.rowData.destinataricc) ? [] : args.rowData.destinataricc.trim().split(' '); let arrRet = []; 
                                                  arrTmp.forEach(elm => { arrRet.push(_.find(this.dsMslObj, elm2 => { return elm2.campo_id === elm }).VALUE); });
                                                  this.MslObj2 = new MultiSelect({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Destinatari CC', popupHeight:'200px', mode: 'Box', floatLabelType: 'Auto',
                                                                                    dataSource: this.dsMslObj, tagging: this.onTagging.bind(this), value: arrRet }); 
                                                  this.MslObj2.appendTo(args.element) }};  
        this.editTemplate6 = { create: () => { let elemMsl = document.createElement('input'); return elemMsl},
                               read: () => { let strRet = '  '; this.MslObj3.value.forEach(elm => { strRet += _.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm }).campo_id + ' '; }); return strRet; },
                               destroy: () => {this.MslObj3.destroy()},
                               write: (args) => { let arrTmp = CustomJS.isNullEmpty(args.rowData.destinatariccn) ? [] : args.rowData.destinatariccn.trim().split(' '); let arrRet = []; 
                                                  arrTmp.forEach(elm => { arrRet.push(_.find(this.dsMslObj, elm2 => { return elm2.campo_id === elm }).VALUE); });
                                                  this.MslObj3 = new MultiSelect({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Destinatari CCN', popupHeight:'200px', mode: 'Box', floatLabelType: 'Auto',
                                                                                dataSource: this.dsMslObj, tagging: this.onTagging.bind(this), value: arrRet }); 
                                                  this.MslObj3.appendTo(args.element) }};  
                                                         
    }
    state = {
        blocking: false,
        dsCat: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    checkDuplicateStato(args) { 
        if (!CustomJS.isNullEmpty(_.find(document.getElementById(args.element.id.substring(0, args.element.id.indexOf(args.element.name))).ej2_instances[0].dataSource, 
                                         elm2 => { return elm2.id_stato.toString() === args.value } )))
            return false;
        else
            return true;
    }
    onTagging(e) {  
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }    
    onRowDatabound(args) {       
    }
    onActionBegin(args){
        if(args.requestType === 'add') {           
            args.data.mittente = 'info@actsrl.it';
        }
        if (args.action === "edit" && args.requestType === "save") {
            args.data.id_tipo_messaggio = this.elemSelezTipoMess;
        }
    }
    onActionBegin2(args){
        if(args.requestType === 'add') {           
            args.data.id_email = this.parentDetails.parentRowData.id_email;
        }
    }
    onActionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuova Email Automatica' : `Dettaglio di ${args.rowData.oggetto}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '01008';
            const dataObj = {
                IdEmail: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_email : args.data[0].id_email,
                IdTipoMess: args.rowData?.id_tipo_messaggio,
                Mittente: CustomJS.isNullEmpty(args.data[0]) ? args.data.mittente : args.data[0].mittente,
                DestinatariA: CustomJS.isNullEmpty(args.data[0]) ? args.data.destinataria : args.data[0].destinataria,
                DestinatariCC: CustomJS.isNullEmpty(args.data[0]) ? args.data.destinataricc : args.data[0].destinataricc,
                DestinatariCCN: CustomJS.isNullEmpty(args.data[0]) ? args.data.destinatariccn : args.data[0].destinatariccn,
                Oggetto: CustomJS.isNullEmpty(args.data[0]) ? args.data.oggetto : args.data[0].oggetto,
                Testo: CustomJS.isNullEmpty(args.data[0]) ? args.data.testo : args.data[0].testo,
                TuttiStati: CustomJS.isNullEmpty(args.data[0]) ? args.data.tutti_stati : args.data[0].tutti_stati,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);                      
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    onActionComplete2(args) {        
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '020072';
            const dataObj = {
                IdEmailStato: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_email_stato : args.data[0].id_email_stato,
                IdEmail: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_email : args.data[0].id_email,
                IdStato: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_stato : args.data[0].id_stato,
                Sollecito: CustomJS.isNullEmpty(args.data[0]) ? args.data.sollecito : args.data[0].sollecito,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => { 
                    console.log(retData);                   
                    if (retData.response === 'KO') {                       
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {  
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);   
                    }                         
            }, error => {                
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    onChangeInvioAuto(args) {
        let valore = args.checked === true ? '1' : '0';
        CustomJS.setParametroDB(9, valore, false).then(ret => {
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });  
    }
    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaCat' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                    {/* <div className='row'></div>  */}
                                        <div className='row'>
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                <div>
                                                    <label htmlFor="EmailAutomatiche" style={{ marginLeft: '50px', marginTop: '50px' }}>Invio Email Automatiche su Cambio Stato</label>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <SwitchComponent id="EmailAutomatiche" checked={true} ref={swObj => this.switchInstance = swObj} change={this.onChangeInvioAuto.bind(this)}></SwitchComponent>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'></div>  
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridModelliEmailStati" dataSource={this.state.dsCat} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} childGrid={this.childGrid} hierarchyPrintMode='All' actionBegin={this.onActionBegin.bind(this)}
                                                    actionComplete={this.onActionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} filterSettings={this.filterSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_email' headerText='' isPrimaryKey='true' visible={false}></ColumnDirective>
                                                        <ColumnDirective field='descr_tipo_mess' headerText="Tipo Mess."  width='6%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} editType='DropDownEdit' edit={this.editTemplate1}></ColumnDirective>
                                                        <ColumnDirective field='mittente' headerText="Mittente"  width='8%' validationRules={{ required: true }} textAlign='Center' allowEditing={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='destinataria' headerText="Destinatari"  width='10%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} edit={this.editTemplate4}></ColumnDirective>
                                                        <ColumnDirective field='destinataricc' headerText="Destinatari CC"  width='10%' textAlign='Center' headerTemplate={headerTemplate} edit={this.editTemplate5}></ColumnDirective>
                                                        <ColumnDirective field='destinatariccn' headerText="Destinatari CCN"  width='10%' textAlign='Center' headerTemplate={headerTemplate} edit={this.editTemplate6}></ColumnDirective>
                                                        <ColumnDirective field='oggetto' headerText="Oggetto"  width='15%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='testo' headerText="Testo"  width='40%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip" edit={this.editTemplate2}></ColumnDirective>
                                                        <ColumnDirective field='tutti_stati' headerText="Tutti Stati"  width='7%' displayAsCheckBox={true} editType='booleanedit' textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='5%' commands={this.commands} textAlign='Right' allowFiltering={false}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Modelli Email Automatiche';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '01008', retData => {
            let GridData1 = _.uniq(retData, elm => elm.id_email);
            let arrTemp = _.filter(retData, (elm) => {return !CustomJS.isNullEmpty(elm.id_stato)});
            let GridData2 = _.map(arrTemp, (elm) => { return {id_email: elm.id_email, id_email_stato: elm.id_email_stato, id_stato: elm.id_stato, descr_stato: elm.descr_stato}});
            this.gridInstance.dataSource = GridData1;
            this.gridInstance.childGrid.dataSource = GridData2;
            this.gridInstance.refresh();   
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
        CustomJS.getParametroDB(9).then(ret => {
            this.switchInstance.checked = ret === '1' ? true : false;
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });  
    }
}