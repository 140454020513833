/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';


function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class Paginabeni extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
        { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } },
        { type: "Sposta", buttonOption: { iconCss: "fas fa-arrow-right fa-10x", cssClass: "e-details" } }];
        this.commands2 = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
        { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } },
        { type: "Sposta", buttonOption: { iconCss: "fas fa-arrow-left fa-10x", cssClass: "e-details" } }];
        this.pageSettings = { pageSize: 15, pageCount: 15 };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
    }
    state = {
        blocking: false,
        showDialog1: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }

    actionComplete(args) {
        $($(args.row).find('button')[2]).hide();
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione = args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '09009';
            const dataObj = {
                IdBene: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_bene : args.data[0].id_bene,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                    if (retData.response === 'OK') {
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        });
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
            if (args.action === "add")
                setTimeout(() => {
                    this.caricaDati();
                }, 200);
        }
        if (args.requestType === "cancel") {
            // $($(args.row).find('button')[2]).show();
        }
    }
    actionComplete2(args) {
        $($(args.row).find('button')[2]).hide();
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione = args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '09010';
            const dataObj = {
                IdAltroBene: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_bene : args.data[0].id_bene,
                DescrizioneAltroBene: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                    if (retData.response === 'OK') {
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        });
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
            if (args.action === "add")
                setTimeout(() => {
                    this.caricaDati();
                }, 200);
        }
        if (args.requestType === "cancel") {
            // $($(args.row).find('button')[2]).show();
        }

    }

    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-arrow-right') > -1) {
            this.dialogInstance.header = 'Attenzione!!';
            this.dialogInstance.content = `Confermare lo spostamento nella tabella Altri Beni?`;
            this.dialogInstance.width = '25%';
            this.dialogInstance.height = '23%';
            this.dialogInstance.minHeight = '23%';
            this.dialogInstance.buttons = [
                {
                    click: () => {
                        this.confermaSpostamento(args, 'right');
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance.show();
        }
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-arrow-left') > -1) {
            this.dialogInstance.header = 'Attenzione!!';
            this.dialogInstance.content = `Confermare lo spostamento nella tabella Beni?`;
            this.dialogInstance.width = '25%';
            this.dialogInstance.height = '23%';
            this.dialogInstance.minHeight = '23%';
            this.dialogInstance.buttons = [
                {
                    click: () => {
                        this.confermaSpostamento(args, 'left');
                    },
                    buttonModel: {
                        content: 'CONFERMA',
                        cssClass: 'e-primary',
                        isPrimary: true
                    }
                },
                {
                    click: () => {
                        this.dialogInstance.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance.show();
        }
    }
    confermaSpostamento(args, arrow) {
        this.dialogInstance.hide();
        // this.toggleBlocking();
        if (arrow === 'right') {
            const dataObj = {
                DescrizioneAltroBene: args.rowData.descrizione,
            };
            const QueryExec = encodeURIComponent("INSERT INTO [09010] (descr_altro_bene) VALUES (@descr_altro_bene)");
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                const dataObj2 = {
                    IdBene: args.rowData.id_bene,
                };
                const QueryExec2 = encodeURIComponent(`DELETE FROM [09009] WHERE [09009].ID = @ID`);
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
                  //  this.toggleBlocking();
                    this.caricaDati();
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }
        if (arrow === 'left') {
            const dataObj = {
                DescrBene: args.rowData.descrizione,
            };
            const QueryExec = encodeURIComponent("INSERT INTO [09009] (bene) VALUES (@bene_str)");
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                const dataObj2 = {
                    IdAltroBene: args.rowData.id_bene,
                };
                const QueryExec2 = encodeURIComponent(`DELETE FROM [09010] WHERE [09010].id_altro_bene = @id_altro_bene`);
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
               //     this.toggleBlocking();
                    this.caricaDati();
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                });
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            });
        }
    }
    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPaginaBeni' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog" target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} height='80%' width='90%'
                    ref={dialog => this.dialogInstance = dialog} isModal={true} >
                </DialogComponent>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-content">
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <h3 style={{ textAlign: 'center' }}>Beni</h3> <br />
                                                <GridComponent id="GridBeni" ref={grid => this.gridInstance = grid} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={false} commandClick={this.onCommandClick.bind(this)}
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} pageSettings={this.pageSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px' }} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_bene' headerText='' isPrimaryKey='true' visible={false}></ColumnDirective>
                                                        <ColumnDirective field='descrizione' headerText="Nome" validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='20%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                                </GridComponent>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <h3 style={{ textAlign: 'center' }}>Altri Beni</h3> <br />
                                                <GridComponent id="GridAltriBeni" ref={grid => this.gridInstance2 = grid} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={false} commandClick={this.onCommandClick.bind(this)}
                                                    actionComplete={this.actionComplete2.bind(this)} toolbarClick={this.toolbarClick.bind(this)} pageSettings={this.pageSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px' }} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_bene' headerText='' isPrimaryKey='true' visible={false}></ColumnDirective>
                                                        <ColumnDirective field='descrizione' headerText="Nome" validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='20%' commands={this.commands2} textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Beni';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '09009', retData => {
            let dsBeni = _.map(retData, (elm) => { return { id_bene: elm.ID, descrizione: elm.bene } });
            this.gridInstance.dataSource = dsBeni;
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '09010', retData2 => {
            let dsAltriBeni = _.map(retData2, (elm) => { return { id_bene: elm.id_altro_bene, descrizione: elm.descr_altro_bene } });
            this.gridInstance2.dataSource = dsAltriBeni;
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        });
    }
}