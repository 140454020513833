/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import RegioniProvince1 from '../assets/RegioniProvince1.json';
import { DatePicker } from '@syncfusion/ej2-react-calendars';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
let elemDdl = null;
let elemDdl2 = null;
let elemDdl3 = null;
let elemDdl4 = null;
let elemDpick1 = null;
export default class PaginaUtente extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } }];
        this.filterSettings = { type: 'CheckBox'};
        this.DdlObj = null;
        this.DdlObj2 = null;
        this.DdlObj3 = null;
        this.DdlObj4 = null;
        this.DtPick1 = null;
        this.MslObj1 = null;
        this.elemSelezProvincia = null;
        this.elemSelezProvNascita = null;
        this.elemSelezMetFatt = null;
        this.elemSelezUtente = null;
        this.dsMslObj = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00002')))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.descrizione } });
        this.dsMslObj.shift();
        this.editTemplate = { create: () => { elemDdl = document.createElement('input'); return elemDdl},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.elemSelezProvincia = args.rowData.provincia; this.DdlObj = new DropDownList({fields:{text: 'TEXT' , value: 'VALUE'}, placeholder:'Provincia', popupHeight:'200px', floatLabelType: 'Auto', 
                                                dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1'))), value: args.rowData.provincia, allowFiltering: true, filterType: 'Contains', 
                              change: (args2) => {args.rowData.provincia = args2.value; this.elemSelezProvincia = args2.value;} }); this.DdlObj.appendTo(elemDdl)}};
        this.editTemplate2 = { create: () => { elemDdl2 = document.createElement('input'); return elemDdl2},
                                read: () => {return this.DdlObj2.text},
                                destroy: () => {this.DdlObj2.destroy()},
                                write: (args) => {this.elemSelezProvNascita = args.rowData.provincia_nascita;this.DdlObj2 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Provincia Nascita', popupHeight:'200px', floatLabelType: 'Auto', 
                                                dataSource:JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1'))), value: args.rowData.provincia_nascita, 
                              change: (args2) => {args.rowData.provincia_nascita = args2.value;this.elemSelezProvNascita = args2.value;} }); this.DdlObj2.appendTo(elemDdl2)}};
        this.editTemplate3 = { create: () => { elemDdl3 = document.createElement('input'); return elemDdl3},
                                read: () => {return this.DdlObj3.text},
                                destroy: () => {this.DdlObj3.destroy()},
                                write: (args) => {this.elemSelezMetFatt = args.rowData.id_metodo_fatturazione;this.DdlObj3 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Metodo Fatturazione', popupHeight:'200px', floatLabelType: 'Auto', 
                                                dataSource:JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaMetodoFatt'))).map(elm => { return { VALUE: elm.id_metodo_fatturazione.toString(), TEXT: elm.descrizione} }), 
                                change: (args2) => {args.rowData.id_metodo_fatturazione = parseInt(args2.value);this.elemSelezMetFatt = parseInt(args2.value);}, value:args.rowData.id_metodo_fatturazione?.toString() }); this.DdlObj3.appendTo(elemDdl3)}};
        this.editTemplate4 = { create: () => { elemDdl4 = document.createElement('input'); return elemDdl4},
                                read: () => {return this.DdlObj4.text},
                                destroy: () => {this.DdlObj4.destroy()},
                                write: (args) => {this.elemSelezUtente = args.rowData.username_derivato; this.DdlObj4 = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:"Utente d'origine", popupHeight:'200px', floatLabelType: 'Auto', 
                                                dataSource:JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09001'))).map(elm => { return { VALUE: elm.username, TEXT: CustomJS.isNullEmpty(elm.nome) && CustomJS.isNullEmpty(elm.cognome)  ? elm.username :  elm.nome + ' ' + elm.cognome}}), 
                                change: (args2) => {args.rowData.username_derivato = args2.value; this.elemSelezUtente = args2.value;}, value:args.rowData.username_derivato }); this.DdlObj4.appendTo(elemDdl4)}};
        this.editTemplate5 = { create: () => { elemDpick1 = document.createElement('input'); return elemDpick1},
                                read: () => {return this.DtPick1.value},
                                destroy: () => {this.DtPick1.destroy()},
                                write: (args) => {this.DtPick1 = new DatePicker({floatLabelType: 'Always', placeholder:'Scegli una data di nascita', value: CustomJS.isNullEmpty(args.rowData[args.column.field]) ? null : new Date(args.rowData[args.column.field])}); this.DtPick1.appendTo(elemDpick1)}};
        this.editTemplate6 = { create: () => { let elemMsl = document.createElement('input'); return elemMsl},
                                read: () => { let strRet = ''; this.MslObj1.value.forEach(elm => { strRet += _.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm }).VALUE + ','; }); strRet=!CustomJS.isNullEmpty(strRet) ? strRet.slice(0,-1) : strRet; return strRet; },
                                destroy: () => {this.MslObj1.destroy()},
                                write: (args) => { let arrTmp = CustomJS.isNullEmpty(args.rowData.compagnie) ? [] : args.rowData.compagnie.trim().split(','); let arrRet = []; 
                                                   arrTmp.forEach(elm => { arrRet.push(_.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm.toString() })?.VALUE); }); 
                                                   this.MslObj1 = new MultiSelect({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Compagnie Riferimento', floatLabelType: 'Auto', popupHeight:'200px', mode: 'Box',
                                                                                   dataSource: this.dsMslObj, tagging: this.onTagging.bind(this), value: arrRet }); 
                                                   this.MslObj1.appendTo(args.element) }};   
    }
    state = {
        blocking: false,
        dsUtenti: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {       
    }
    onTagging(e) {  
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }    
    actionBegin(args) {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'password' || elm.field === 'CQ' || elm.field === 'CR' || elm.field === 'smtp_server' || elm.field === 'smtp_porta' 
                    || elm.field === 'smtp_utente' || elm.field === 'smtp_password' || elm.field === 'smtp_ssl' || elm.field === 'smtp_mittente' 
                    || elm.field === 'smtp_email' || elm.field === 'compagnie' || elm.field === 'iban' || elm.field === 'p_iva' || elm.field === 'cod_fiscale'
                    || elm.field === 'telefono1' || elm.field === 'aux1') 
                    elm.visible = true;
            });
        }
        if (args.action === "edit" && args.requestType === "save") {
            args.data.provincia = this.elemSelezProvincia;
            args.data.provincia_nascita = this.elemSelezProvNascita;
            args.data.id_metodo_fatturazione = this.elemSelezMetFatt;
            args.data.username_derivato = this.elemSelezUtente;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo utente' : `Dettaglio di ${args.rowData.username}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '09001';
            const dataObj = {
                IdUtente: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_utente : args.data[0].id_utente,
                NomeUtente: CustomJS.isNullEmpty(args.data[0]) ? args.data.username : args.data[0].username,
                Nome: CustomJS.isNullEmpty(args.data[0]) ? args.data.nome : args.data[0].nome,
                Cognome: CustomJS.isNullEmpty(args.data[0]) ? args.data.cognome : args.data[0].cognome,
                RagioneSociale: CustomJS.isNullEmpty(args.data[0]) ? args.data.ragione_sociale : args.data[0].ragione_sociale,
                Indirizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.indirizzo : args.data[0].indirizzo,
                Citta: CustomJS.isNullEmpty(args.data[0]) ? args.data.citta : args.data[0].citta,
                Provincia: args.rowData?.provincia,
                CAP: CustomJS.isNullEmpty(args.data[0]) ? args.data.cap : args.data[0].cap,
                Email1: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Password: CustomJS.isNullEmpty(args.data[0]) ? args.data.password : args.data[0].password,
                Bloccato: CustomJS.isNullEmpty(args.data[0]) ? args.data.bloccato : args.data[0].bloccato,
                CodiceFiscale: CustomJS.isNullEmpty(args.data[0]) ? args.data.cod_fiscale : args.data[0].cod_fiscale,
                PIVA: CustomJS.isNullEmpty(args.data[0]) ? args.data.p_iva : args.data[0].p_iva,
                DataNascita: CustomJS.isNullEmpty(args.data[0]) ? args.data.data_nascita : args.data[0].data_nascita,
                LuogoNascita: CustomJS.isNullEmpty(args.data[0]) ? args.data.luogo_nascita : args.data[0].luogo_nascita,
                ProvinciaNascita: args.rowData?.provincia_nascita,
                Telefono1: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Aux1: CustomJS.isNullEmpty(args.data[0]) ? args.data.aux1 : args.data[0].aux1,
                IdMetodoFatt: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_metodo_fatturazione : args.data[0].id_metodo_fatturazione,
                IBAN: CustomJS.isNullEmpty(args.data[0]) ? args.data.iban : args.data[0].iban,
                UserSito: CustomJS.isNullEmpty(args.data[0]) ? args.data.user_sito : args.data[0].user_sito,
                PasswordSito: CustomJS.isNullEmpty(args.data[0]) ? args.data.password_sito : args.data[0].password_sito,
                UtenteDerivato: CustomJS.isNullEmpty(args.data[0]) ? args.data.username_derivato : args.data[0].username_derivato,
                CQ: CustomJS.isNullEmpty(args.data[0]) ? args.data.CQ : args.data[0].CQ,
                CR: CustomJS.isNullEmpty(args.data[0]) ? args.data.CR : args.data[0].CR,
                SMTPServer: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_server : args.data[0].smtp_server,
                SMTPPorta: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_porta : args.data[0].smtp_porta,
                SMTPUtente: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_utente : args.data[0].smtp_utente,
                SMTPPassword: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_password : args.data[0].smtp_password,
                SMTPSSL: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_ssl : args.data[0].smtp_ssl,
                SMTPMittente: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_mittente : args.data[0].smtp_mittente,
                SMTPEmail: CustomJS.isNullEmpty(args.data[0]) ? args.data.smtp_email : args.data[0].smtp_email,
                Compagnie : CustomJS.isNullEmpty(args.data[0]) ? args.data.compagnie : args.data[0].compagnie
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });   
                        this.gridInstance.columns.forEach(elm => {
                            if (elm.field === 'password' || elm.field === 'CQ' || elm.field === 'CR' || elm.field === 'smtp_server' || elm.field === 'smtp_porta' 
                                || elm.field === 'smtp_utente' || elm.field === 'smtp_password' || elm.field === 'smtp_ssl' || elm.field === 'smtp_mittente' 
                                || elm.field === 'smtp_email' || elm.field === 'compagnie' || elm.field === 'iban' || elm.field === 'p_iva' || elm.field === 'cod_fiscale'
                                || elm.field === 'telefono1' || elm.field === 'aux1') 
                                elm.visible = false;
                        });                    
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200); 
        }
        if (args.requestType === 'cancel')
            this.gridInstance.columns.forEach(elm => {
                if (elm.field === 'password' || elm.field === 'CQ' || elm.field === 'CR' || elm.field === 'smtp_server' || elm.field === 'smtp_porta' || elm.field === 'smtp_utente' || elm.field === 'smtp_password'
                    || elm.field === 'smtp_ssl' || elm.field === 'smtp_mittente' || elm.field === 'smtp_email' || elm.field === 'compagnie') 
                    elm.visible = false;
            }); 
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaUtenti' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                    <div className='row'></div> 
                                    <div className='row'></div>
                                    <div className='row'></div>  
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridUtenti" dataSource={this.state.dsUtenti} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={false} actionBegin={this.actionBegin.bind(this)} filterSettings={this.filterSettings}
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} 
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_utente' headerText='ID utente' isPrimaryKey='true' visible={false} width='25%'  headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='username' headerText="Username"  width='20%' validationRules={{ required: true }} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='password' headerText="Password"  width='20%' validationRules={{ required: true }} visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='nome' headerText="Nome"  width='20%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cognome' headerText="Cognome"  width='20%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='ragione_sociale' headerText="Ragione sociale"  width='30%' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='indirizzo' headerText="Indirizzo"  width='25%'   headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='citta' headerText="Città"  width='18%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_provincia' headerText="Provincia"  width='15%' EditType='DropDownEdit' edit={this.editTemplate}  headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cap' headerText="CAP"  width='15%'   headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='data_nascita' headerText="Data Nascita"  width='20%'  type='Date' format='dd/MM/yyyy' edit={this.editTemplate5} visible={true} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='luogo_nascita' headerText="Luogo Nascita"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_provincia_nascita' headerText="Provincia Nascita" EditType='DropDownEdit' edit={this.editTemplate2} width='20%' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono1' headerText="Telefono"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email1' headerText="Email"  width='35%'  headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='aux1' headerText="Chiave Cryptazione Importi (Emissione Competenze)"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_metodo_fatt' headerText="ID metodo Fatturazione" EditType='DropDownEdit' edit={this.editTemplate3} width='20%' visible={true} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cod_fiscale' headerText="Codice Fiscale"  width='20%' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='p_iva' headerText="P.IVA"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='iban' headerText="IBAN"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='CQ' headerText="CQ" visible={false} displayAsCheckBox={true} editType='booleanedit' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='CR' headerText="CR" visible={false} displayAsCheckBox={true} editType='booleanedit' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='compagnie' headerText="Compagnie" edit={this.editTemplate6} width='20%' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='user_sito' headerText="User sito"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_server' headerText="Server SMTP"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_porta' headerText="Porta SMTP"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_utente' headerText="Utente SMTP"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_password' headerText="Password SMTP"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_ssl' headerText="SMTP SSL"  width='20%'  visible={false} displayAsCheckBox={true} editType='booleanedit' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_mittente' headerText="Mittente SMTP"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='smtp_email' headerText="Email SMTP"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='password_sito' headerText="Password sito"  width='20%'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='username_derivato' headerText="Utente d'origine"  width='20%'  EditType='DropDownEdit' edit={this.editTemplate4} visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='bloccato' headerText="Bloccato"  width='20%' displayAsCheckBox={true} editType='booleanedit' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} allowFiltering={false} textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Utenti';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '09001', retData => {
            let GridData1 = _.filter(retData, (elm) => {return !CustomJS.isNullEmpty(elm.id_utente)});
            GridData1.forEach(elm => {
                let indOK = 0;
                let objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia ? idx : 0;  return elm3 === elm.provincia; })) })
                elm.descr_provincia = objReg?.capoluoghi[indOK];
                indOK = 0;
                objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia_nascita ? idx : 0;  return elm3 === elm.provincia_nascita; })) })
                elm.descr_provincia_nascita = objReg?.capoluoghi[indOK];
            })
            this.gridInstance.dataSource= GridData1;
            this.gridInstance.refresh();   
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
    }
}