import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { NavMenu } from './NavMenu';
import PaginaHome from './PaginaHome';
import IdleTimeout from './IdleTimeout';
import IdleTimeout2 from './IdleTimeout2';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
export class Layout extends Component {

  static displayName = Layout.name;

  render() {
    return (
      <React.Fragment>
        <IdleTimeout2></IdleTimeout2>
        <NavMenu />
        <ToastContainer transition={Zoom} containerId='toastContainer1' style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
        <div id='blockUIGenerico' style={{ display: 'none' }}></div>
        <div id='spinnerUIGenerico' style={{ display: 'none' }} role="status"></div>
        <div id="bgImageDiv2"></div>
        <div id='contenutoDinamico2'></div>
      </React.Fragment>);
  }

  componentDidMount() {
    sessionStorage.setItem("JFTechnologyPaginaLogin", "false");
    const container = document.getElementById('contenutoDinamico2');
    const root = createRoot(container); 

    root.render(<CallbackWrapper callback={() => {
        setTimeout(() => {
            document.getElementById('sidebar-menu').style.visibility = 'hidden';
            toast.warn('Attendere prego...il menu comparirà alla fine della fase di caricamento delle tabelle generali', {
              containerId: 'toastContainer1',
              onClose: () => toast.dismiss(),
              position: "bottom-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              newestOnTop: true,
              rtl: false,
              pauseOnFocusLoss: true
            });
        }, 80);
    }}><PaginaHome></PaginaHome></CallbackWrapper>);
  }
}