/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
let elemDdl = null;
export default class PaginaStatiSinistro extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Edit', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: false, allowEditing: true, allowDeleting: false, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } }];
        this.txtObj1 = null;
        this.pageSettings = {pageSize : 10, pageCount : 10};
        this.filterSettings = { type: 'CheckBox'};
        this.searchSettings = {operator: 'contains' , ignoreCase: true};
        this.DdlObj = null;
        this.elemSelezStato = null;
        this.editTemplate = { create: () => { elemDdl = document.createElement('input'); return elemDdl},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.elemSelezStato = args.rowData.id_tipo_stato; this.DdlObj = new DropDownList({fields:{text: 'descrizione', value: 'id_tipo_stato'}, placeholder:'Stato', popupHeight:'200px', floatLabelType: 'Auto', 
                                                dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02004'))).map(elm => {return {id_tipo_stato: elm.id_tipo_stato.toString(), descrizione: elm.descrizione}}), value: args.rowData.id_tipo_stato?.toString(),
                                                allowFiltering: true, filterType: 'Contains', 
                              change: (args2) => {
                                  args.rowData.id_tipo_stato = parseInt(args2.value);
                                  this.elemSelezStato = parseInt(args2.value);
                                } }); this.DdlObj.appendTo(elemDdl) }};
    }
    state = {
        blocking: false,
        dsStatiSinistro: [],
        dsAbbinamentiStatiAzione: []
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    actionBegin(args){
        if (args.action === "edit" && args.requestType === "save") {
            args.data.id_tipo_stato = this.elemSelezStato;
        }
    }
    actionComplete(args) {
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '02004';
            const dataObj = {
                IdTipoStato: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_tipo_stato : args.data[0].id_tipo_stato,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione,
                Colore: CustomJS.isNullEmpty(args.data[0]) ? args.data.colore : args.data[0].colore,
                Nascosto: CustomJS.isNullEmpty(args.data[0]) ? args.data.nascosto : args.data[0].nascosto,
                TTimer: CustomJS.isNullEmpty(args.data[0]) ? args.data.t_timer : args.data[0].t_timer,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200); 
        }
    }
    actionComplete2(args) {
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {     
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '02010';
            const dataObj = {
                IdAzione: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_azione : args.data[0].id_azione,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione,
                IdStato: args.rowData?.id_tipo_stato,
                GeneraNota: CustomJS.isNullEmpty(args.data[0]) ? args.data.genera_nota : args.data[0].genera_nota,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200); 
        }
    }
    toolbarClick1(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance1.csvExport();
                break;
        }
    }
    toolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance2.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance2.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaStatiSinistro' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content"> 
                                    {/* <div className='row'></div>  
                                    <div className='row'></div>
                                    <div className='row'></div> */}
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{marginLeft: '50px'}}>
                                            <h5>GESTIONE STATI</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <GridComponent id="GridStatiSinistro" dataSource={this.state.dsStatiSinistro} ref={ grid => this.gridInstance1 = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={false} 
                                                actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick1.bind(this)} pageSettings={this.pageSettings} searchSettings={this.searchSettings} filterSettings={this.filterSettings}
                                                toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='id_tipo_stato' headerText='ID Tipo Stato' isPrimaryKey='true' textAlign='Center' visible={false}></ColumnDirective>
                                                    <ColumnDirective field='descrizione' headerText="Descrizione" width='40%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='colore' headerText='Colore'  visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='nascosto' headerText='Nascosto' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                                                    <ColumnDirective field='t_timer' headerText='Timer' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                </ColumnsDirective>
                                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                            </GridComponent>
                                        </div>
                                    </div>
                                    <div className='row'></div>
                                    <div className='row'></div>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{marginLeft: '50px'}}>
                                            <h5>ABBINAMENTI STATI-AZIONI</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <GridComponent id="GridStatiSinistroAbbin" ref={ grid => this.gridInstance2 = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={false} actionBegin={this.actionBegin.bind(this)}
                                                actionComplete={this.actionComplete2.bind(this)} toolbarClick={this.toolbarClick2.bind(this)} pageSettings={this.pageSettings} searchSettings={this.searchSettings} filterSettings={this.filterSettings}
                                                toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='id_azione' headerText='ID Tipo Stato' isPrimaryKey='true' textAlign='Center' visible={false}></ColumnDirective>
                                                    <ColumnDirective field='descrizione' headerText="Descrizione" width='40%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='descr_stato' headerText='ID Stato' textAlign='Center' headerTemplate={headerTemplate} editType='DropDownEdit' edit={this.editTemplate}></ColumnDirective>
                                                    <ColumnDirective field='genera_nota' headerText='Genera Nota' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                                                    <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                </ColumnsDirective>
                                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                            </GridComponent>
                                        </div>
                                    </div>                         
                                </div>                              
                            </div>
                        </div> 
                    </div>
                </div>
               </div>
               </BlockUi>
        </React.Fragment>
        );
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Stati Sinistro';
        this.caricaDati();
    }
    caricaDati(){
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        this.toggleBlocking();
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '02004', retData => {
            this.gridInstance1.dataSource = retData;
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
        });
        const QueryExec = encodeURIComponent(`SELECT [02010].*, [02004].id_tipo_stato, [02004].descrizione AS descr_stato FROM [02010] LEFT OUTER JOIN [02004] ON [02010].id_stato = [02004].id_tipo_stato`);     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token, 'GET', QueryExec, '', retData => {
            this.dsAbbinamentiStatiAzione = retData.map(elm => { return {id_azione: elm.id_azione, id_stato: elm.id_stato, genera_nota: elm.genera_nota, id_tipo_stato: elm.id_tipo_stato, descr_stato: elm.descr_stato, descrizione: elm.descrizione}}); 
            this.gridInstance2.dataSource = this.dsAbbinamentiStatiAzione;  
            this.toggleBlocking();   
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });            
    }
}