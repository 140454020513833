/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let elencoCompagnie = '';
    props[props.column.field]?.split(',').forEach(elm => {
        elencoCompagnie += _.find(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00002'))), elm2 => { return elm2.id_compagnia.toString() === elm }).descrizione + ', ';
    }); 
    elencoCompagnie = elencoCompagnie.slice(0,-2);
    let renderDiv = <div><span>{elencoCompagnie}</span></div>;
    return (
        renderDiv
    );
}

export default class PaginaIndennizzo extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.dsMslObj = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00002')))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.descrizione } });
        this.editTemplate1 = { create: () => { let elemMsl = document.createElement('input'); return elemMsl},
                            // read: () => { let strRet = ''; this.MslObj1.value.forEach(elm => { strRet += _.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm }).VALUE + ','; }); strRet=!CustomJS.isNullEmpty(strRet) ? strRet.slice(0,-1) : strRet; return strRet; },
                               read: () => { return this.MslObj1.value.join(',') },   
                               destroy: () => {this.MslObj1.destroy()},
                               write: (args) => { let arrTmp = CustomJS.isNullEmpty(args.rowData.compagnie) ? [] : args.rowData.compagnie.trim().split(','); let arrRet = []; 
                                                  arrTmp.forEach(elm => { arrRet.push(_.find(this.dsMslObj, elm2 => { return elm2.VALUE === elm.toString() })?.VALUE); }); 
                                                  this.MslObj1 = new MultiSelect({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Compagnie Riferimento', floatLabelType: 'Auto', popupHeight:'200px', mode: 'Box',
                                                                                  dataSource: this.dsMslObj, tagging: this.onTagging.bind(this), value: arrRet }); 
                                                  this.MslObj1.appendTo(args.element) }}; 
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.pageSettings = {pageSize : 999};
    }
    state = {
        blocking: false,
        dsTipoIndennizzi: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onTagging(e) {  
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }    
    actionComplete(args) {
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '02011';
            const dataObj = {
                IdTipoIndennizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_tipo_indennizzo : args.data[0].id_tipo_indennizzo,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione,
                Compagnie: CustomJS.isNullEmpty(args.data[0]) ? args.data.compagnie : args.data[0].compagnie
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200); 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>
            <BlockUi id='blockUIPaginaIndennizzo' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
            <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                    <div className='row'></div> 
                                    <div className='row'></div>
                                    <div className='row'></div>  
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridTipiIndennizzi" dataSource={this.state.dsTipoIndennizzi} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} 
                                                    allowPaging={false} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={true} 
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} pageSettings={this.pageSettings} allowTextWrap={true}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_tipo_indennizzo' headerText='' isPrimaryKey='true'  visible={false}></ColumnDirective>
                                                        <ColumnDirective field='descrizione' headerText="Nome" validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='compagnie' headerText="Compagnia Riferimento"  width='30%' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate} edit={this.editTemplate1}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                </BlockUi>
            </React.Fragment>
        );
    }
    componentDidMount(){
        this.caricaDati();
    }
    caricaDati(){
        this.toggleBlocking();
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload')).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '02011', retData => {
            this.setState({dsTipoIndennizzi: retData})
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
    }
}